import { Button, Grid, Input } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/Iconify';

export function SearchInput({
  searchValue,
  setSearchValue,
  search,
}: {
  searchValue: string;
  setSearchValue: (value: string) => void;
  search: () => void;
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Input
          type="text"
          placeholder="Zoek op naam"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          style={{ width: '100%' }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              search();
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Button variant="contained" color="primary" onClick={search}>
          <Iconify icon="eva:search-outline" sx={{ mr: 1 }} width={22} height={22} />
          Zoeken
        </Button>
      </Grid>
    </Grid>
  );
}
