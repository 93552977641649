import * as yup from 'yup';

export const loginFormSchema = yup.object().shape({
  username: yup.string().required('Dit veld is verplicht'),
  password: yup.string().required('Dit veld is verplicht'),
});

export const sensorFormSchema = yup.object().shape({
  name: yup.string().min(4, 'Naam moet langer zijn dan 4 karakters').required('Dit veld is verplicht'),
  isSet: yup.boolean().oneOf([true, false]).required('Dit veld is verplicht'),
  idSensor1: yup.string().required('Dit veld is verplicht'),
  dwtw: yup.string().required('Dit veld is verplicht'),
  group: yup.string().required('Dit veld is verplicht'),
  project: yup.string().required('Dit veld is verplicht'),
  postalCode: yup.string().required('Dit veld is verplicht'),
  city: yup.string().required('Dit veld is verplicht'),
  household: yup
    .number()
    .integer()
    .typeError('Dit veld kan alleen een getal bevatten')
    .required('Dit veld is verplicht'),
  datePlacement: yup.date(),
  dateRemoval: yup.date(),
  tapwatergenerator: yup.string().required('Dit veld is verplicht'),
  residence: yup.string().required('Dit veld is verplicht'),
  comments: yup.string(),
});

export const groepFormSchema = yup.object().shape({
  name: yup.string().min(4, 'Naam moet langer zijn dan 4 karakters').required('Dit veld is verplicht'),
});

export const dwtwFormSchema = yup.object().shape({
  name: yup.string().min(4, 'Naam moet langer zijn dan 4 karakters').required('Dit veld is verplicht'),
});

export const projectFormSchema = yup.object().shape({
  name: yup.string().min(4, 'Naam moet langer zijn dan 4 karakters').required('Dit veld is verplicht'),
});

export const recordFormSchema = yup.object().shape({
  sensor: yup.string().required('Dit veld is verplicht'),
  recordsSensor1: yup.string().required('Dit veld is verplicht'),
});
