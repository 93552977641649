import { Grid, Container, Typography } from '@mui/material';
import React from 'react';

import Page from 'src/components/Page';
import { GroupForm } from 'src/sections/@dashboard/app';

export function AddGroup() {
  return (
    <Page title="Groep toevoegen">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ mb: 3 }}>
              Groep toevoegen
            </Typography>
            <GroupForm />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
