import React from "react";
import { Container, Grid } from "@mui/material";
import { LoginForm } from "src/sections/@login";
import Page from "src/components/Page";

export default function Login() {
  return (
    <Page title="Login">
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            height: "80vh",
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
          }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <LoginForm />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
