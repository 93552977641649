import React from 'react';
import { TextField } from '@mui/material';
import { useField } from 'formik';

const TextFieldWrapper = ({ name, ...otherProps }) => {
  const [field, meta] = useField(name);
  const configTextField: Record<string, any> = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'standard',
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }
  return <TextField {...configTextField} sx={{ pt: 2 }} />;
};

export default TextFieldWrapper;
