import { Grid, Container, Typography } from '@mui/material';
import Page from '../components/Page';
import { SensorForm } from '../sections/@dashboard/app';
import React from 'react';

export function AddSensor() {
  return (
    <Page title="Sensor toevoegen">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ mb: 3 }}>
              Sensors toevoegen
            </Typography>
            <SensorForm />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
