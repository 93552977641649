import 'react-toastify/dist/ReactToastify.css';
import { Box, Grid, Button } from '@mui/material';
import {
  fetchAllSingleRecords,
  fetchAllSensorSetRecords,
  fetchAllExportSensorSets,
  fetchSensorsForExport,
} from 'src/services/fetchApi';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import Iconify from 'src/components/Iconify';
import 'src/css/styles.css';
import React from 'react';

export default function Export() {
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} sx={undefined} />;
  const [singleRecords, setRecords] = useState([]);
  const [sensorSetRecords, setSensorSetRecords] = useState([]);
  const [sensorSets, setSensorSets] = useState([]);
  const [sensors, setSensors] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      let fetchedSingleRecords = await fetchAllSingleRecords();
      setRecords(fetchedSingleRecords);
      let fetchedSensorSetRecords = await fetchAllSensorSetRecords();
      setSensorSetRecords(fetchedSensorSetRecords);
      let fetchedSensorSets = await fetchAllExportSensorSets();
      setSensorSets(fetchedSensorSets);
      let sensors = await fetchSensorsForExport();
      console.log(sensors);
      setSensors(sensors);
    };
    fetch();
  }, []);

  const recordHeaders = [
    { label: 'id', key: 'id' },
    { label: 'recordId', key: 'recordId' },
    { label: 'avgTemperature', key: 'avgTemperature' },
    { label: 'duration', key: 'duration' },
    { label: 'usage', key: 'usage' },
    { label: 'startTime', key: 'startTime' },
    { label: 'initialTemperature', key: 'initialTemperature' },
    { label: 'idSensor', key: 'idSensor' },
    { label: 'usedEnergy', key: 'usedEnergy' },
    { label: 'sensorSetRecordId', key: 'sensorSetRecordId' },
    { label: 'flagged', key: 'flagged' },
  ];

  const sensorSetHeaders = [
    { label: 'id', key: 'id' },
    { label: 'idSensor1', key: 'idSensor1' },
    { label: 'idSensor2', key: 'idSensor2' },
  ];

  const sensorHeaders = [
    { label: 'id', key: 'id' },
    { label: 'isSet', key: 'isSet' },
    { label: 'sensorSetId', key: 'sensorSetId' },
    { label: 'idSensor1', key: 'idSensor1' },
    { label: 'name', key: 'name' },
    { label: 'dwtw', key: 'dwtw.name' },
    { label: 'group', key: 'group.name' },
    { label: 'project', key: 'project.name' },
    { label: 'city', key: 'city' },
    { label: 'postalCode', key: 'postalCode' },
    { label: 'datePlacement', key: 'datePlacement' },
    { label: 'dateRemoval', key: 'dateRemoval' },
    { label: 'household', key: 'household' },
    { label: 'tapwatergenerator', key: 'tapwatergenerator' },
    { label: 'residence', key: 'residence' },
    { label: 'comments', key: 'comments' },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
      <Grid container spacing={2}>
        <Box sx={{ width: '100%', mt: 4, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Grid item xs={12} md={3} lg={3}>
            <CSVLink
              className="export-btn"
              filename="single_records.csv"
              headers={recordHeaders}
              data={singleRecords ? singleRecords : null}
            >
              <Button fullWidth variant="contained" sx={{ p: 2, ml: 5, textTransform: 'none' }}>
                {getIcon('eva:download-outline')} Export all single records
              </Button>
            </CSVLink>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CSVLink
              className="export-btn"
              filename="sensorset_records.csv"
              headers={recordHeaders}
              data={sensorSetRecords ? sensorSetRecords : null}
            >
              <Button fullWidth variant="contained" sx={{ p: 2, ml: 5, textTransform: 'none' }}>
                {getIcon('eva:download-outline')} Export all sensorset records
              </Button>
            </CSVLink>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CSVLink
              className="export-btn"
              filename="sensorsets.csv"
              headers={sensorSetHeaders}
              data={sensorSets ? sensorSets : null}
            >
              <Button fullWidth variant="contained" sx={{ p: 2, ml: 5, textTransform: 'none !important' }}>
                {getIcon('eva:download-outline')} Export sensorsets
              </Button>
            </CSVLink>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CSVLink
              className="export-btn"
              filename="sensors.csv"
              headers={sensorHeaders}
              data={sensors ? sensors : null}
            >
              <Button fullWidth variant="contained" sx={{ p: 2, ml: 5, textTransform: 'none !important' }}>
                {getIcon('eva:download-outline')} Export sensors
              </Button>
            </CSVLink>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
}
