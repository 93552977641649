import { Grid, Container } from '@mui/material';
import React from 'react';
import Page from 'src/components/Page';
import { ShowerSensorList } from 'src/sections/@dashboard/app';

export default function Shower() {
  return (
    <Page title="Douchegedrag">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ShowerSensorList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
