import { Grid, Container, Typography } from '@mui/material';
import React from 'react';

import Page from 'src/components/Page';
import { DwtwForm } from 'src/sections/@dashboard/app';

export function AddDWTW() {
  return (
    <Page title="DWTW toevoegen">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ mb: 3 }}>
              DWTW toevoegen
            </Typography>
            <DwtwForm />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
