import { Box, Typography, Grid } from '@mui/material';
import { TextField, Button } from 'src/components/FormsUI';
import { Formik, Form } from 'formik';
import { projectFormSchema } from 'src/utils/formSchemas';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createNewProject } from 'src/services/fetchApi';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const INITIAL_FORM_STATE = {
  name: '',
};

export default function ProjectForm() {
  const navigate = useNavigate();

  const onSubmitForm = useCallback(
    async (values, actions) => {
      try {
        let response = await createNewProject(values);
        toast.info(response);
        actions.resetForm();

        navigate('/dashboard/projects');
      } catch (error) {
        toast.error(error.message);
        console.log(error);
      }
    },
    [navigate]
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
      <Formik
        initialValues={{ ...INITIAL_FORM_STATE }}
        validationSchema={projectFormSchema}
        onSubmit={onSubmitForm}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: 500 }}>Projectgegevens</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField name="name" label="Projectnaam" />
              </Grid>

              <Grid item xs={12} md={6} lg={2}>
                <Button>{props.isSubmitting ? <CircularProgress /> : 'Maak project aan'}</Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
