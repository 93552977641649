// component
import React from 'react';
import Iconify from 'src/components/Iconify';

const getIcon = (name: any) => <Iconify icon={name} width={22} height={22} sx={undefined} />;

const navConfig = [
  { title: 'Douchegedrag', path: '/dashboard/douchegedrag', icon: getIcon('eva:droplet-outline') },
  { title: 'Energiebesparing', path: '/dashboard/energiebesparing', icon: getIcon('eva:flash-outline') },
  // { title: 'Upload', path: '/dashboard/upload', icon: getIcon('eva:upload-outline') },
  { title: 'Sensors', path: '/dashboard/sensors', icon: getIcon('eva:thermometer-plus-outline') },
  { title: 'Projects', path: '/dashboard/projects', icon: getIcon('eva:folder-add-outline') },
  { title: 'Groepen', path: '/dashboard/groups', icon: getIcon('eva:people-outline') },
  { title: 'DWTWs', path: '/dashboard/dwtws', icon: getIcon('eva:sync-fill') },
  { title: 'Export', path: '/dashboard/export', icon: getIcon('eva:hard-drive-outline') },
];

export default navConfig;
