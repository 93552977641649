function fetchOptions(options: RequestInit, includeCredentials = true) {
  const update = { ...options };

  if (update.body) {
    update.headers = {
      ...update.headers,
      'Content-Type': 'application/json',
    };
  }

  if (includeCredentials) {
    update.credentials = 'include';
  }

  return update;
}

export const fetchSensorsAndSets = async () => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/find`,
    fetchOptions({
      method: 'POST',
    })
  );

  const response = await request.json();

  return response;
};

export const fetchAllSensors = async () => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({}),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllSensorsFiltered = async (name: string) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({
        name: {
          contains: name,
          mode: 'insensitive',
        },
      }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchSensorsForExport = async () => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/find`,
    fetchOptions({
      method: 'POST',
    })
  );
  const response = await request.json();
  return response;
};

export const createSensor = async (values) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/create`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify(values),
    })
  );
  const response = await request.json();
  return response;
};

export const deleteSensor = async (id: number) => {
  return await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/delete`,
    fetchOptions({
      method: 'DELETE',
      body: JSON.stringify({ id }),
    })
  );
};

export const fetchSingleSensor = async (sensor) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/findOne`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({ name: sensor }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllSensorSets = async () => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({ isSet: true }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllExportSensorSets = async () => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor-set/find`,
    fetchOptions({
      method: 'POST',
    })
  );
  const response = await request.json();
  return response;
};

export const filterFetchSensorsSets = async (
  groupsArr,
  projectsArr,
  dwtwArr,
  tapwatergeneratorsArr,
  citiesArr,
  residencesArr,
  householdsArr
) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({
        groupId: {
          in: groupsArr.length > 0 ? groupsArr : undefined,
        },
        projectId: {
          in: projectsArr.length > 0 ? projectsArr : undefined,
        },
        showerHeatRecoveryId: {
          in: dwtwArr.length > 0 ? dwtwArr : undefined,
        },
        tapwatergenerator: {
          in: tapwatergeneratorsArr.length > 0 ? tapwatergeneratorsArr : undefined,
        },
        city: {
          in: citiesArr.length > 0 ? citiesArr : undefined,
        },
        residence: {
          in: residencesArr.length > 0 ? residencesArr : undefined,
        },
        household: {
          in: householdsArr.length > 0 ? householdsArr : undefined,
        },
        isSet: true,
      }),
    })
  );
  const response = await request.json();
  return response;
};

export const filterFetchSensors = async (
  groupsArr,
  projectsArr,
  dwtwArr,
  tapwatergeneratorsArr,
  citiesArr,
  residencesArr,
  householdsArr
) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({
        groupId: {
          in: groupsArr.length > 0 ? groupsArr : undefined,
        },
        projectId: {
          in: projectsArr.length > 0 ? projectsArr : undefined,
        },
        showerHeatRecoveryId: {
          in: dwtwArr.length > 0 ? dwtwArr : undefined,
        },
        tapwatergenerator: {
          in: tapwatergeneratorsArr.length > 0 ? tapwatergeneratorsArr : undefined,
        },
        city: {
          in: citiesArr.length > 0 ? citiesArr : undefined,
        },
        residence: {
          in: residencesArr.length > 0 ? residencesArr : undefined,
        },
        household: {
          in: householdsArr.length > 0 ? householdsArr : undefined,
        },
        isSet: false,
      }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllGroups = async () => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/group/find`,
    fetchOptions({
      method: 'POST',
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllGroupsFiltered = async (name: string) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/group/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({
        name: {
          contains: name,
          mode: 'insensitive',
        },
      }),
    })
  );
  const response = await request.json();
  return response;
};

export const createNewGroup = async (name: string) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/group/create`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify(name),
    })
  );
  const response = await request.json();
  return response;
};

export const deleteGroup = async (id: number) => {
  return await fetch(
    `${process.env.REACT_APP_API_URL}/group/delete`,
    fetchOptions({
      method: 'DELETE',
      body: JSON.stringify({ id }),
    })
  );
};

export const fetchAllProjects = async () => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/project/find`,
    fetchOptions({
      method: 'POST',
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllProjectsFiltered = async (name: string) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/project/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({
        name: {
          contains: name,
          mode: 'insensitive',
        },
      }),
    })
  );
  const response = await request.json();
  return response;
};

export const createNewProject = async (name) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/project/create`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify(name),
    })
  );
  const response = await request.json();
  return response;
};

export const deleteProject = async (id: number) => {
  return await fetch(
    `${process.env.REACT_APP_API_URL}/project/delete`,
    fetchOptions({
      method: 'DELETE',
      body: JSON.stringify({ id }),
    })
  );
};

export const fetchAllDWTWs = async () => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/dwtw/find`,
    fetchOptions({
      method: 'POST',
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllDWTWsFiltered = async (name: string) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/dwtw/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({
        name: {
          contains: name,
          mode: 'insensitive',
        },
      }),
    })
  );
  const response = await request.json();
  return response;
};

export const createNewDWTW = async (name: string) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/dwtw/create`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify(name),
    })
  );
  const response = await request.json();
  return response;
};

export const deleteDWTW = async (id: number) => {
  return await fetch(
    `${process.env.REACT_APP_API_URL}/dwtw/delete`,
    fetchOptions({
      method: 'DELETE',
      body: JSON.stringify({ id }),
    })
  );
};

export const fetchAllCities = async () => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/find/cities`,
    fetchOptions({
      method: 'POST',
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllHouseHolds = async () => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/find/households`,
    fetchOptions({
      method: 'POST',
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllYears = async () => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/record/find-all-years`,
    fetchOptions({
      method: 'POST',
    })
  );
  const response = await request.json();
  return response;
};

export const fetchSelectedSensorSet = async (name) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/findOne`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({ name: name }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchSelectedSensor = async (name) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor/findOne`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({ name: name }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllRecordsByDay = async (sensorIds, day, month, year) => {
  let dayMonthYearString = `${year}-${month && month <= 9 ? '0' + month : month}-${day && day <= 9 ? '0' + day : day}`;

  let gte = new Date(
    `${new Date(`${dayMonthYearString}`).toLocaleString('default', { month: 'long' })} ${day}, ${year} , 00:00:00`
  );
  let lte = new Date(
    `${new Date(`${dayMonthYearString}`).toLocaleString('default', { month: 'long' })} ${day}, ${year} , 23:59:59`
  );

  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/record/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({
        idSensor: {
          in: sensorIds,
        },
        flagged: false,
        startTime: {
          gte,
          lte,
        },
      }),
    })
  );
  let response = await request.json();
  return response;
};

export const fetchRecordsByDay = async (recordIds) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/record/find/day`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({ recordIds: recordIds, flagged: false }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllRecordsByYearMonth = async (sensorIds, month, year) => {
  let yearMonthString = `${year}-${month && month <= 9 ? '0' + month : month}`;

  if (year && month === 12) {
    year++;
    month = '0' + 1;
  } else if (month && month < 9) {
    month = '0' + (month + 1);
  } else if (month && month >= 9) {
    month++;
  }

  let gte = new Date(`${yearMonthString}`);
  let lte = new Date(`${year}-${month}`);

  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/record/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({
        idSensor: {
          in: sensorIds,
        },
        flagged: false,
        startTime: {
          gte: gte,
          lte: lte,
        },
      }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchRecordsByMonth = async (recordIds) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/record/find/month`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({ recordIds: recordIds, flagged: false }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllSingleRecords = async () => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/record/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({
        sensorSetRecordId: null,
      }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllSensorSetRecords = async () => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/record/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({
        sensorSetRecordId: {
          not: null,
        },
      }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchAllRecordsByYear = async (sensorIds, year) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/record/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({
        idSensor: {
          in: sensorIds,
        },
        flagged: false,
        startTime: {
          gte: new Date(`${year}-01-01`),
          lte: new Date(`${year + 1}-12-31`),
        },
      }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchRecordsByYear = async (year, recordIds) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/record/find/year`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({ year: year, recordIds: recordIds, flagged: false }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchSensorSetRecordsByDay = async (sensorSetRecordIds) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor-set-record/find/day`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({ sensorSetRecordIds: sensorSetRecordIds }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchSensorSetRecordsByMonth = async (sensorSetRecordIds) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor-set-record/find`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({ sensorSetRecordIds: sensorSetRecordIds }),
    })
  );
  const response = await request.json();
  return response;
};

export const fetchSensorSetRecordsByYear = async (year, sensorSetRecordIds) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/sensor-set-record/find/year`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({ year: year, sensorSetRecordIds: sensorSetRecordIds }),
    })
  );
  const response = await request.json();
  return response;
};

export const createRecord = async (records, isSet, sensor_data) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/record/create`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify({ records, isSet, sensor_data }),
    })
  );
  const response = await request.json();
  return response;
};

export const login = async (values) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/auth/login`,
    fetchOptions({
      method: 'POST',
      body: JSON.stringify(values),
    })
  );

  return response;
};

export const logout = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/auth/logout`,
    fetchOptions({
      method: 'POST',
    })
  );

  return response;
};

export const validate = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/auth/validate`,
    fetchOptions({
      method: 'POST',
      credentials: 'include',
    })
  );

  return response;
};
