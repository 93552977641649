import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar, Button } from '@mui/material';
import account from 'src/_mock/account';
import useResponsive from 'src/hooks/useResponsive';
import Scrollbar from 'src/components/Scrollbar';
import NavSection from 'src/components/NavSection';
import navConfig from './NavConfig';
import Iconify from 'src/components/Iconify';
import { logout } from 'src/services/fetchApi';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} sx={undefined} />;

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const handleLogout = async () => {
    await logout();

    (window as Window).location = '/auth/login';
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Link component={RouterLink} to="/" sx={{ display: 'flex', justifyContent: 'center' }}>
        <img alt="sanura_logo" width={'210px'} src="static/mock-images/logo/sanura_insights.png" />
      </Link>
      <NavSection navConfig={navConfig} sx={{ mt: 3 }} />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ mb: 3 }}>
        <Link underline="none" component={RouterLink} to="/">
          <AccountStyle>
            <Avatar src={account.photoURL} variant="rounded" sx={{ width: 25, height: 25 }} />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {account.displayName}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>
      <Button
        variant="contained"
        onClick={handleLogout}
        sx={{ borderRadius: '0px', padding: '1rem', fontSize: '1rem', gap: '.5rem' }}
      >
        {getIcon('eva:log-out-outline')} Logout
      </Button>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
