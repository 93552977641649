import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { Button, TextField } from 'src/components/FormsUI';
import { login } from 'src/services/fetchApi';
import { useAuth } from 'src/utils/Auth';
import { loginFormSchema } from 'src/utils/formSchemas';

const INITIAL_FORM_STATE = {
  username: '',
  password: '',
};

export default function LoginForm() {
  const navigate = useNavigate();
  const auth = useAuth();

  const onSubmitForm = useCallback(
    (values, actions) => {
      try {
        login(values).then(async (response) => {
          if (response.status === 200) {
            toast.success('Authenticatie succesvol');

            auth.isLoggedIn(true);

            setTimeout(() => {
              navigate('/dashboard', { replace: true });
            }, 1000);
          } else {
            toast.error('Verkeerd wachtwoord of gebruikersnaam');
            actions.resetForm();
          }
        });
      } catch (error) {
        console.log(error);
        toast.error(error);
      }
    },
    [auth, navigate]
  );

  if (auth.isAuthenticated) {
    return <Navigate to={'/dashboard'} replace />;
  }

  return (
    <Box>
      <Formik
        initialValues={{ ...INITIAL_FORM_STATE }}
        validationSchema={loginFormSchema}
        onSubmit={onSubmitForm}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ fontWeight: 900 }}>
                  Sanura
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: '.85rem', letterSpacing: '1px' }}>
                  DASHBOARD PANEL
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField name="username" label="Username" />
              </Grid>
              <Grid item xs={12}>
                <TextField name="password" type="password" label="Password" />
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <Button disabled={props.isSubmitting}>
                  {props.isSubmitting ? <CircularProgress size={22} /> : 'Login'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
