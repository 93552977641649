import Graph from './Chart';
import Iconify from 'src/components/Iconify';
import 'react-toastify/dist/ReactToastify.css';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Select as SelectUI, TextField } from 'src/components/FormsUI';
import { MONTHS_OPTIONS, TIMELINE_OPTIONS, RESIDENCE_OPTIONS, TAPWATERGENERATOR_OPTIONS } from 'src/utils/selectItems';
import {
  filterFetchSensors,
  fetchAllGroups,
  fetchAllProjects,
  fetchAllCities,
  fetchAllRecordsByYear,
  fetchAllRecordsByYearMonth,
  fetchAllSensors,
  fetchSelectedSensor,
  fetchRecordsByMonth,
  fetchRecordsByYear,
  fetchAllDWTWs,
  fetchAllHouseHolds,
  fetchAllRecordsByDay,
  fetchRecordsByDay,
  fetchAllYears,
} from 'src/services/fetchApi';
import { Typography, Grid, Card, CircularProgress, Box, Autocomplete, Chip, Checkbox, Divider } from '@mui/material';
import getDaysInMonth from 'date-fns/getDaysInMonth';

export default function ShowerSensorList() {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const today = new Date();
  const currentYear = today.getFullYear();
  const [daysInSelectedMonth, setDaysInSelectedMonth] = useState<number>();
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sensorOptions, setSensorOptions] = useState([]);
  const [cities, setCities] = useState();
  const [dwtws, setDWTWs] = useState();
  const [households, setHouseHolds] = useState();
  const [years, setYears] = useState();
  const [groupsOptions, setGroupsOptions] = useState();
  const [projectsOptions, setProjectsOptions] = useState();
  const [dates, setDates] = useState([]);
  const [usedEnergy, setUsedEnergy] = useState([]);
  const [amountOfShowersTaken, setAmountOfShowersTaken] = useState([]);
  const [totalUsedEnergy, setTotalUsedEnergy] = useState<number | number[]>();
  const [totalTimeInShower, setTotalTimeInShower] = useState([]);
  const [totalAmountOfLiters, setTotalAmountOfLiters] = useState([]);

  const getIcon = (name) => <Iconify icon={name} width={60} height={60} sx={undefined} />;

  // initial form state
  const INITIAL_FORM_STATE = useMemo(
    () => ({
      sensors: [],
      timeline: 'Jaar',
      day: '',
      month: '',
      year: currentYear,
      groups: [],
      projects: [],
      dwtw: [],
      tapwatergenerators: [],
      residences: [],
      households: [],
      cities: [],
    }),
    [currentYear]
  );

  useEffect(() => {
    const fetch = async () => {
      setSensorOptions(await fetchAllSensors());
      setGroupsOptions(await fetchAllGroups());
      setProjectsOptions(await fetchAllProjects());
      setCities(await fetchAllCities());
      setDWTWs(await fetchAllDWTWs());
      setHouseHolds(await fetchAllHouseHolds());
      setYears(await fetchAllYears());
      onSubmitForm(INITIAL_FORM_STATE);
      setFilter(false);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckedFilter = (event) => {
    setFilter(event.target.checked);
  };

  const onSubmitForm = async (values) => {
    try {
      // from form receive timeline, month, year and sensorset (optional)
      let { timeline, day, month, year, sensors } = values;
      // get days in the selected month, 07 => 31 days in july
      setDaysInSelectedMonth(new Date(year, month, 0).getDate());
      // fallback if left empty, return error
      if (timeline === '' && values.sensorset === '') {
        return toast.error('Selecteer alle velden om data in te zien');
      }
      // if the normal search is used and the timeline selected is daily
      if (!filter && timeline === 'Dag' && day && sensors.length > 0 && month && year) {
        // fetch sensors that match the filter
        let sensorIds = [];
        // get all values from multi select field. Loop through and find object data of each selected sensor
        // then push the ID's of the sensorsets to array defined above.
        for (let i = 0; i < sensors.length; i++) {
          let response = await fetchSelectedSensor(sensors[i].name);
          sensorIds.push(response.idSensor1);
        }
        // fetching all records on the selected dayMonthYear (2022-07-09) -> this returns an array of records (amount of showers taken)
        const records = await fetchAllRecordsByDay(sensorIds, day, month, year);
        // storing all those record ids
        let recordIds = [];
        records.forEach((record) => recordIds.push(record.id));
        recordIds = [...new Set(recordIds)];

        // fetch all records according to the record id's
        const recordsByDay = await fetchRecordsByDay(recordIds);
        // initializing data objects for graph
        let hourlyTotalUsedEnergy = [];
        let hourlyUsedEnergy = [];
        let hourlyDates = [];
        let hourlyAmountOfShowersTaken = [];
        let hourlyTotalAmountOfLiters = [];
        let hourlyTotalTimeInShower = [];
        // creating a full array of hours in a day. This is to populate empty data
        for (let i = 0; i < 24; i++) {
          hourlyTotalUsedEnergy.push(0);
          hourlyDates.push(`${(i && i <= 9) || i === 0 ? '0' + i : i}`);
          hourlyAmountOfShowersTaken.push(0);
          hourlyUsedEnergy.push(0);
          hourlyTotalAmountOfLiters.push(0);
          hourlyTotalTimeInShower.push(0);
        }
        if (typeof recordsByDay !== 'string') {
          hourlyDates.forEach((hour, index) => {
            recordsByDay.forEach((record) => {
              let hourString = `${
                new Date(record.time).getHours() <= 9
                  ? '0' + new Date(record.time).getHours()
                  : new Date(record.time).getHours()
              }`;
              if (hour === hourString) {
                hourlyUsedEnergy[index] = Number(Math.abs(record.total_energy).toFixed());
                hourlyAmountOfShowersTaken[index] = Number(record.count);
                hourlyTotalAmountOfLiters[index] = Number(record.liters);
                hourlyTotalTimeInShower[index] = Number(record.duration);
              }
            });
          });
        } else {
          toast.warning(recordsByDay);
        }

        // sum of everything in the saved energy array
        hourlyTotalUsedEnergy = hourlyUsedEnergy.reduce((a, b) => {
          return a + b;
        }, 0);

        // setting the data
        setDates(hourlyDates);
        setTotalUsedEnergy(hourlyTotalUsedEnergy);
        setUsedEnergy(hourlyUsedEnergy);
        setAmountOfShowersTaken(hourlyAmountOfShowersTaken);
        setTotalAmountOfLiters(hourlyTotalAmountOfLiters);
        setTotalTimeInShower(hourlyTotalTimeInShower);
      }
      // if the normal search is used and the timeline selected is monthly
      if (!filter && timeline === 'Maand' && month && sensors.length > 0 && year) {
        let sensorIds = [];
        // get all values from multi select field. Loop through and find object data of each selected sensor
        // then push the ID's of the sensorsets to array defined above.
        for (let i = 0; i < sensors.length; i++) {
          let response = await fetchSelectedSensor(sensors[i].name);
          sensorIds.push(response.idSensor1);
        }

        // get all records given the id of sensor 1, the yearmonth string (02-07-2022)
        const records = await fetchAllRecordsByYearMonth(sensorIds, month, year);
        // storing all those record ids
        let recordIds = [];
        records.forEach((record) => recordIds.push(record.id));
        recordIds = [...new Set(recordIds)];
        // get all records given the recordIds in the previous step (extra step)
        let recordsByMonth = await fetchRecordsByMonth(recordIds);

        // initializing data objects for graph
        let dailyTotalUsedEnergy = 0;
        let dailyUsedEnergy = [];
        let dailyDates = [];
        let dailyAmountOfShowersTaken = [];
        let dailyTotalAmountOfLiters = [];
        let dailyTotalTimeInShower = [];

        // populating the days array with the amount of days in a month
        // also populating the two other array with 0's till the length of the dates array.
        for (let i = 1; i < daysInSelectedMonth + 1; i++) {
          dailyDates.push(`${i < 10 ? '0' + i : i}`);
          dailyAmountOfShowersTaken.push(0);
          dailyUsedEnergy.push(0);
          dailyTotalAmountOfLiters.push(0);
          dailyTotalTimeInShower.push(0);
        }
        // converting the date from 2022/07/04 to europe version 04/07/2022
        if (typeof recordsByMonth === 'object' && records.length > 0) {
          recordsByMonth.map((record) => {
            return (record.date = new Date(record.date).toLocaleDateString('en-GB'));
          });
          // allocating the data by index
          dailyDates.forEach((date, index) => {
            recordsByMonth.forEach((record) => {
              if (date === record.date.substring(0, 2)) {
                dailyUsedEnergy[index] = Number(Math.abs(record.total_energy).toFixed());
                dailyAmountOfShowersTaken[index] = Number(record.count);
                dailyTotalAmountOfLiters[index] = Number(record.liters);
                dailyTotalTimeInShower[index] = Number(record.duration);
              }
            });
          });

          // sum of savedEnergy array
          dailyTotalUsedEnergy = dailyUsedEnergy.reduce((a, b) => {
            return a + b;
          }, 0);
        } else {
          toast.warning(recordsByMonth);
        }

        // setting the data
        setDates(dailyDates);
        setTotalUsedEnergy(dailyTotalUsedEnergy);
        setUsedEnergy(dailyUsedEnergy);
        setAmountOfShowersTaken(dailyAmountOfShowersTaken);
        setTotalAmountOfLiters(dailyTotalAmountOfLiters);
        setTotalTimeInShower(dailyTotalTimeInShower);
      }
      // if the normal search is used and the timeline selected is yearly
      if (!filter && timeline === 'Jaar' && sensors.length > 0 && year) {
        let sensorIds = [];
        // get all values from multi select field. Loop through and find object data of each selected sensor
        // then push the ID's of the sensorsets to array defined above.
        for (let i = 0; i < sensors.length; i++) {
          let response = await fetchSelectedSensor(sensors[i].name);
          sensorIds.push(response.idSensor1);
        }
        // get all records in the selected year with the id of sensor 1
        const records = await fetchAllRecordsByYear(sensorIds, year);
        // storing all those record ids
        let recordIds = [];
        records.forEach((record) => recordIds.push(record.id));
        recordIds = [...new Set(recordIds)];
        // fetching all records according to the record id's (extra step)
        let recordsByYear = await fetchRecordsByYear(year, recordIds);

        // initializing data objects for graph
        let totalUsedEnergy = [];
        let usedEnergy = [];
        let dates = [];
        let amountOfShowersTaken = [];
        let totalAmountOfLiters = [];
        let totalTimeInShower = [];

        // creating a full array of months
        MONTHS_OPTIONS.forEach((month) => {
          totalUsedEnergy.push(0);
          dates.push(month.name.substring(0, 3));
          amountOfShowersTaken.push(0);
          usedEnergy.push(0);
          totalAmountOfLiters.push(0);
          totalTimeInShower.push(0);
        });
        // if the response is not a string (meaning an error) then allocate index to value
        if (typeof recordsByYear !== 'string') {
          // loop through each month and set the data available to the corresponding index (0 => january)
          dates.forEach((date, index) => {
            recordsByYear.forEach((record) => {
              if (date === record.month.substring(0, 3)) {
                usedEnergy[index] = Number(Math.abs(record.total_energy).toFixed());
                amountOfShowersTaken[index] = Number(record.count);
                totalAmountOfLiters[index] = Number(record.liters);
                totalTimeInShower[index] = Number(record.duration);
              }
            });
          });
        } else {
          toast.warning(recordsByYear);
        }
        // sum of everything in the saved energy array
        totalUsedEnergy = usedEnergy.reduce((a, b) => {
          return a + b;
        }, 0);

        // setting the data
        setDates(dates);
        setTotalUsedEnergy(totalUsedEnergy);
        setUsedEnergy(usedEnergy);
        setAmountOfShowersTaken(amountOfShowersTaken);
        setTotalAmountOfLiters(totalAmountOfLiters);
        setTotalTimeInShower(totalTimeInShower);
      }
      // if the filter search is used and the timeline selected is daily
      if (filter && timeline === 'Dag' && day && month && year) {
        // init variables
        let sensorIds = [];
        let { groups, projects, dwtw, tapwatergenerators, cities, residences, households } = values;
        let groupsArr = [];
        let projectsArr = [];
        let dwtwArr = [];
        let tapwatergeneratorsArr = [];
        let residencesArr = [];
        let householdsArr = [];
        let citiesArr = [];

        // ** Need to find a way to simplify this, all form values are pushed in the array as objects
        if (groups && groups.length > 0) {
          groups.forEach((group) => {
            groupsArr.push(group.id);
          });
        }

        if (projects && projects.length > 0) {
          projects.forEach((project) => {
            projectsArr.push(project.id);
          });
        }

        if (dwtw && dwtw.length > 0) {
          dwtw.forEach((dwtw) => {
            dwtwArr.push(dwtw.id);
          });
        }

        if (tapwatergenerators && tapwatergenerators.length > 0) {
          tapwatergenerators.forEach((tapwatergenerator) => {
            tapwatergeneratorsArr.push(tapwatergenerator.name);
          });
        }

        if (residences && residences.length > 0) {
          residences.forEach((residence) => {
            residencesArr.push(residence.name);
          });
        }

        if (households && households.length > 0) {
          households.forEach((household) => {
            householdsArr.push(Number(household.household));
          });
        }

        if (cities && cities.length > 0) {
          cities.forEach((city) => {
            citiesArr.push(city.city);
          });
        }

        // fetch sensors that match the filter
        const sensors = await filterFetchSensors(
          groupsArr,
          projectsArr,
          dwtwArr,
          tapwatergeneratorsArr,
          citiesArr,
          residencesArr,
          householdsArr
        );
        sensors.forEach((sensor) => {
          sensorIds.push(sensor.idSensor1);
        });
        // fetching all records on the selected dayMonthYear (2022-07-09) -> this returns an array of records (amount of showers taken)
        const records = await fetchAllRecordsByDay(sensorIds, day, month, year);
        // storing all those record ids
        let recordIds = [];
        records.forEach((record) => recordIds.push(record.id));
        recordIds = [...new Set(recordIds)];

        // fetch all records according to the record id's
        const recordsByDay = await fetchRecordsByDay(recordIds);
        // initializing data objects for graph
        let hourlyTotalUsedEnergy = 0;
        let hourlyUsedEnergy = [];
        let hourlyDates = [];
        let hourlyAmountOfShowersTaken = [];
        let hourlyTotalAmountOfLiters = [];
        let hourlyTotalTimeInShower = [];
        // creating a full array of hours in a day. This is to populate empty data
        for (let i = 0; i < 24; i++) {
          hourlyDates.push(`${(i && i <= 9) || i === 0 ? '0' + i : i}`);
          hourlyAmountOfShowersTaken.push(0);
          hourlyUsedEnergy.push(0);
          hourlyTotalAmountOfLiters.push(0);
          hourlyTotalTimeInShower.push(0);
        }
        if (typeof recordsByDay !== 'string') {
          hourlyDates.forEach((hour, index) => {
            recordsByDay.forEach((record) => {
              let hourString = `${
                new Date(record.time).getHours() <= 9
                  ? '0' + new Date(record.time).getHours()
                  : new Date(record.time).getHours()
              }`;
              if (hour === hourString) {
                hourlyUsedEnergy[index] = Number(Math.abs(record.total_energy).toFixed());
                hourlyAmountOfShowersTaken[index] = Number(record.count);
                hourlyTotalAmountOfLiters[index] = Number(record.liters);
                hourlyTotalTimeInShower[index] = Number(record.duration);
              }
            });
          });
        } else {
          toast.warning(recordsByDay);
        }

        // sum of everything in the saved energy array
        hourlyTotalUsedEnergy = hourlyUsedEnergy.reduce((a, b) => {
          return a + b;
        }, 0);

        // setting the data
        setDates(hourlyDates);
        setTotalUsedEnergy(hourlyTotalUsedEnergy);
        setUsedEnergy(hourlyUsedEnergy);
        setAmountOfShowersTaken(hourlyAmountOfShowersTaken);
        setTotalAmountOfLiters(hourlyTotalAmountOfLiters);
        setTotalTimeInShower(hourlyTotalTimeInShower);
      }
      // if the filter search is used and the timeline selected is monthly
      if (filter && timeline === 'Maand' && month && year) {
        // init variables
        let sensorIds = [];
        let { groups, projects, dwtw, tapwatergenerators, cities, residences, households } = values;
        let groupsArr = [];
        let projectsArr = [];
        let dwtwArr = [];
        let tapwatergeneratorsArr = [];
        let residencesArr = [];
        let householdsArr = [];
        let citiesArr = [];

        // ** Need to find a way to simplify this, all form values are pushed in the array as objects
        if (groups && groups.length > 0) {
          groups.forEach((group) => {
            groupsArr.push(group.id);
          });
        }

        if (projects && projects.length > 0) {
          projects.forEach((project) => {
            projectsArr.push(project.id);
          });
        }

        if (dwtw && dwtw.length > 0) {
          dwtw.forEach((dwtw) => {
            dwtwArr.push(dwtw.name);
          });
        }

        if (tapwatergenerators && tapwatergenerators.length > 0) {
          tapwatergenerators.forEach((tapwatergenerator) => {
            tapwatergeneratorsArr.push(tapwatergenerator.name);
          });
        }

        if (residences && residences.length > 0) {
          residences.forEach((residence) => {
            residencesArr.push(residence.name);
          });
        }

        if (households && households.length > 0) {
          households.forEach((household) => {
            householdsArr.push(Number(household.household));
          });
        }

        if (cities && cities.length > 0) {
          cities.forEach((city) => {
            citiesArr.push(city.city);
          });
        }

        // fetch sensors that match the filter
        const sensors = await filterFetchSensors(
          groupsArr,
          projectsArr,
          dwtwArr,
          tapwatergeneratorsArr,
          citiesArr,
          residencesArr,
          householdsArr
        );
        sensors.forEach((sensor) => {
          sensorIds.push(sensor.idSensor1);
        });

        // get all records given the id of sensor 1, the yearmonth string (02-07-2022)
        const records = await fetchAllRecordsByYearMonth(sensorIds, month, year);
        // storing all those record ids
        let recordIds = [];
        records.forEach((record) => recordIds.push(record.id));
        recordIds = [...new Set(recordIds)];
        // get all records given the recordIds in the previous step (extra step)
        let recordsByMonth = await fetchRecordsByMonth(recordIds);

        // initializing data objects for graph
        let dailyTotalUsedEnergy = 0;
        let dailyUsedEnergy = [];
        let dailyDates = [];
        let dailyAmountOfShowersTaken = [];
        let dailyTotalAmountOfLiters = [];
        let dailyTotalTimeInShower = [];

        // populating the days array with the amount of days in a month
        // also populating the two other array with 0's till the length of the dates array.
        for (let i = 1; i < daysInSelectedMonth + 1; i++) {
          dailyDates.push(`${i < 10 ? '0' + i : i}`);
          dailyAmountOfShowersTaken.push(0);
          dailyUsedEnergy.push(0);
          dailyTotalAmountOfLiters.push(0);
          dailyTotalTimeInShower.push(0);
        }
        // converting the date from 2022/07/04 to europe version 04/07/2022
        if (typeof recordsByMonth === 'object' && records.length > 0) {
          recordsByMonth.map((record) => {
            return (record.date = new Date(record.date).toLocaleDateString('en-GB'));
          });
          // allocating the data by index
          dailyDates.forEach((date, index) => {
            recordsByMonth.forEach((record) => {
              if (date === record.date.substring(0, 2)) {
                dailyUsedEnergy[index] = Number(Math.abs(record.total_energy).toFixed());
                dailyAmountOfShowersTaken[index] = Number(record.count);
                dailyTotalAmountOfLiters[index] = Number(record.liters);
                dailyTotalTimeInShower[index] = Number(record.duration);
              }
            });
          });

          // sum of savedEnergy array
          dailyTotalUsedEnergy = dailyUsedEnergy.reduce((a, b) => {
            return a + b;
          }, 0);
        } else {
          toast.warning(recordsByMonth);
        }

        // setting the data
        setDates(dailyDates);
        setTotalUsedEnergy(dailyTotalUsedEnergy);
        setUsedEnergy(dailyUsedEnergy);
        setAmountOfShowersTaken(dailyAmountOfShowersTaken);
        setTotalAmountOfLiters(dailyTotalAmountOfLiters);
        setTotalTimeInShower(dailyTotalTimeInShower);
      }
      // if the filter search is used and the timeline selected is monthly
      if (filter && timeline === 'Jaar' && year) {
        // init variables
        let sensorIds = [];
        let { groups, projects, dwtw, tapwatergenerators, cities, residences, households } = values;
        let groupsArr = [];
        let projectsArr = [];
        let dwtwArr = [];
        let tapwatergeneratorsArr = [];
        let residencesArr = [];
        let householdsArr = [];
        let citiesArr = [];

        // ** Need to find a way to simplify this, all form values are pushed in the array as objects
        if (groups && groups.length > 0) {
          groups.forEach((group) => {
            groupsArr.push(group.id);
          });
        }

        if (projects && projects.length > 0) {
          projects.forEach((project) => {
            projectsArr.push(project.id);
          });
        }

        if (dwtw && dwtw.length > 0) {
          dwtw.forEach((dwtw) => {
            dwtwArr.push(dwtw.id);
          });
        }

        if (tapwatergenerators && tapwatergenerators.length > 0) {
          tapwatergenerators.forEach((tapwatergenerator) => {
            tapwatergeneratorsArr.push(tapwatergenerator.name);
          });
        }

        if (residences && residences.length > 0) {
          residences.forEach((residence) => {
            residencesArr.push(residence.name);
          });
        }

        if (households && households.length > 0) {
          households.forEach((household) => {
            householdsArr.push(Number(household.household));
          });
        }

        if (cities && cities.length > 0) {
          cities.forEach((city) => {
            citiesArr.push(city.city);
          });
        }

        // fetch sensors that match the filter
        const sensors = await filterFetchSensors(
          groupsArr,
          projectsArr,
          dwtwArr,
          tapwatergeneratorsArr,
          citiesArr,
          residencesArr,
          householdsArr
        );
        sensors.forEach((sensor) => {
          sensorIds.push(sensor.idSensor1);
        });

        // get all records in the selected year with all sensors
        const records = await fetchAllRecordsByYear(sensorIds, year);
        // storing all those record ids
        let recordIds = [];
        records.forEach((record) => recordIds.push(record.id));
        recordIds = [...new Set(recordIds)];
        // fetching all records according to the record id's (extra step)
        let recordsByYear = await fetchRecordsByYear(year, recordIds);
        // initializing data objects for graph
        let monthlyTotalUsedEnergy = [];
        let monthlyUsedEnergy = [];
        let monthlyDates = [];
        let monthlyAmountOfShowersTaken = [];
        let monthlyTotalAmountOfLiters = [];
        let monthlyTotalTimeInShower = [];

        // creating a full array of months
        MONTHS_OPTIONS.forEach((month) => {
          monthlyTotalUsedEnergy.push(0);
          monthlyDates.push(month.name.substring(0, 3));
          monthlyAmountOfShowersTaken.push(0);
          monthlyUsedEnergy.push(0);
          monthlyTotalAmountOfLiters.push(0);
          monthlyTotalTimeInShower.push(0);
        });
        // if the response is not a string (meaning an error) then allocate index to value
        if (typeof recordsByYear !== 'string') {
          // loop through each month and set the data available to the corresponding index (0 => january)
          monthlyDates.forEach((date, index) => {
            recordsByYear.forEach((record) => {
              if (date === record.month.substring(0, 3)) {
                monthlyUsedEnergy[index] = Number(Math.abs(record.total_energy).toFixed());
                monthlyAmountOfShowersTaken[index] = Number(record.count);
                monthlyTotalAmountOfLiters[index] = Number(record.liters);
                monthlyTotalTimeInShower[index] = Number(record.duration);
              }
            });
          });
        } else {
          toast.warning(recordsByYear);
        }
        // sum of everything in the saved energy array
        monthlyTotalUsedEnergy = monthlyUsedEnergy.reduce((a, b) => {
          return a + b;
        }, 0);

        // setting the data
        setDates(monthlyDates);
        setTotalUsedEnergy(monthlyTotalUsedEnergy);
        setUsedEnergy(monthlyUsedEnergy);
        setAmountOfShowersTaken(monthlyAmountOfShowersTaken);
        setTotalAmountOfLiters(monthlyTotalAmountOfLiters);
        setTotalTimeInShower(monthlyTotalTimeInShower);
      }
      // if the filter is not used and no sensors are selected, still fetch data daily
      if (!filter && !sensors.length && timeline === 'Dag' && day && month && year) {
        // init variables
        let sensorIds = [];

        // setting to empty to call all possible sensors.
        let groupsArr = [];
        let projectsArr = [];
        let dwtwArr = [];
        let tapwatergeneratorsArr = [];
        let residencesArr = [];
        let householdsArr = [];
        let citiesArr = [];

        // fetch sensors that match the filter
        const sensors = await filterFetchSensors(
          groupsArr,
          projectsArr,
          dwtwArr,
          tapwatergeneratorsArr,
          citiesArr,
          residencesArr,
          householdsArr
        );
        sensors.forEach((sensor) => {
          sensorIds.push(sensor.idSensor1);
        });
        // fetching all records on the selected dayMonthYear (2022-07-09) -> this returns an array of records (amount of showers taken)
        const records = await fetchAllRecordsByDay(sensorIds, day, month, year);
        console.log(records);
        // storing all those record ids
        let recordIds = [];
        records.forEach((record) => recordIds.push(record.id));
        recordIds = [...new Set(recordIds)];

        // fetch all records according to the record id's
        const recordsByDay = await fetchRecordsByDay(recordIds);
        // initializing data objects for graph
        let hourlyTotalUsedEnergy = 0;
        let hourlyUsedEnergy = [];
        let hourlyDates = [];
        let hourlyAmountOfShowersTaken = [];
        let hourlyTotalAmountOfLiters = [];
        let hourlyTotalTimeInShower = [];
        // creating a full array of hours in a day. This is to populate empty data
        for (let i = 0; i < 24; i++) {
          hourlyDates.push(`${(i && i <= 9) || i === 0 ? '0' + i : i}`);
          hourlyAmountOfShowersTaken.push(0);
          hourlyUsedEnergy.push(0);
          hourlyTotalAmountOfLiters.push(0);
          hourlyTotalTimeInShower.push(0);
        }
        if (typeof recordsByDay !== 'string') {
          hourlyDates.forEach((hour, index) => {
            recordsByDay.forEach((record) => {
              let hourString = `${
                new Date(record.time).getHours() <= 9
                  ? '0' + new Date(record.time).getHours()
                  : new Date(record.time).getHours()
              }`;
              if (hour === hourString) {
                hourlyUsedEnergy[index] = Number(Math.abs(record.total_energy).toFixed());
                hourlyAmountOfShowersTaken[index] = Number(record.count);
                hourlyTotalAmountOfLiters[index] = Number(record.liters);
                hourlyTotalTimeInShower[index] = Number(record.duration);
              }
            });
          });
        } else {
          toast.warning(recordsByDay);
        }

        // sum of everything in the saved energy array
        hourlyTotalUsedEnergy = hourlyUsedEnergy.reduce((a, b) => {
          return a + b;
        }, 0);

        // setting the data
        setDates(hourlyDates);
        setTotalUsedEnergy(hourlyTotalUsedEnergy);
        setUsedEnergy(hourlyUsedEnergy);
        setAmountOfShowersTaken(hourlyAmountOfShowersTaken);
        setTotalAmountOfLiters(hourlyTotalAmountOfLiters);
        setTotalTimeInShower(hourlyTotalTimeInShower);
      }
      // if the filter is not used and no sensors are selected, still fetch data monthly
      if (!filter && !sensors.length && timeline === 'Maand' && month && year) {
        // init variables
        let sensorIds = [];

        // setting to empty to call all possible sensors.
        let groupsArr = [];
        let projectsArr = [];
        let dwtwArr = [];
        let tapwatergeneratorsArr = [];
        let residencesArr = [];
        let householdsArr = [];
        let citiesArr = [];

        // fetch sensors that match the filter
        const sensors = await filterFetchSensors(
          groupsArr,
          projectsArr,
          dwtwArr,
          tapwatergeneratorsArr,
          citiesArr,
          residencesArr,
          householdsArr
        );
        sensors.forEach((sensor) => {
          sensorIds.push(sensor.idSensor1);
        });

        // get all records given the id of sensor 1, the yearmonth string (02-07-2022)
        const records = await fetchAllRecordsByYearMonth(sensorIds, month, year);
        // storing all those record ids
        let recordIds = [];
        records.forEach((record) => recordIds.push(record.id));
        recordIds = [...new Set(recordIds)];
        // get all records given the recordIds in the previous step (extra step)
        let recordsByMonth = await fetchRecordsByMonth(recordIds);

        // initializing data objects for graph
        let dailyTotalUsedEnergy = 0;
        let dailyUsedEnergy = [];
        let dailyDates = [];
        let dailyAmountOfShowersTaken = [];
        let dailyTotalAmountOfLiters = [];
        let dailyTotalTimeInShower = [];

        // populating the days array with the amount of days in a month
        // also populating the two other array with 0's till the length of the dates array.
        for (let i = 1; i < daysInSelectedMonth + 1; i++) {
          dailyDates.push(`${i < 10 ? '0' + i : i}`);
          dailyAmountOfShowersTaken.push(0);
          dailyUsedEnergy.push(0);
          dailyTotalAmountOfLiters.push(0);
          dailyTotalTimeInShower.push(0);
        }
        // converting the date from 2022/07/04 to europe version 04/07/2022
        if (typeof recordsByMonth === 'object' && records.length > 0) {
          recordsByMonth.map((record) => {
            return (record.date = new Date(record.date).toLocaleDateString('en-GB'));
          });
          // allocating the data by index
          dailyDates.forEach((date, index) => {
            recordsByMonth.forEach((record) => {
              if (date === record.date.substring(0, 2)) {
                dailyUsedEnergy[index] = Number(Math.abs(record.total_energy).toFixed());
                dailyAmountOfShowersTaken[index] = Number(record.count);
                dailyTotalAmountOfLiters[index] = Number(record.liters);
                dailyTotalTimeInShower[index] = Number(record.duration);
              }
            });
          });

          // sum of savedEnergy array
          dailyTotalUsedEnergy = dailyUsedEnergy.reduce((a, b) => {
            return a + b;
          }, 0);
        } else {
          toast.warning(recordsByMonth);
        }

        // setting the data
        setDates(dailyDates);
        setTotalUsedEnergy(dailyTotalUsedEnergy);
        setUsedEnergy(dailyUsedEnergy);
        setAmountOfShowersTaken(dailyAmountOfShowersTaken);
        setTotalAmountOfLiters(dailyTotalAmountOfLiters);
        setTotalTimeInShower(dailyTotalTimeInShower);
      }
      // if the filter is not used and no sensors are selected, still fetch data yearly
      if (!filter && !sensors.length && timeline === 'Jaar' && year) {
        // init variables
        let sensorIds = [];

        // setting to empty to call all possible sensors.
        let groupsArr = [];
        let projectsArr = [];
        let dwtwArr = [];
        let tapwatergeneratorsArr = [];
        let residencesArr = [];
        let householdsArr = [];
        let citiesArr = [];

        // fetch sensors that match the filter
        const sensors = await filterFetchSensors(
          groupsArr,
          projectsArr,
          dwtwArr,
          tapwatergeneratorsArr,
          citiesArr,
          residencesArr,
          householdsArr
        );
        sensors.forEach((sensor) => {
          sensorIds.push(sensor.idSensor1);
        });

        // get all records in the selected year with all sensors
        const records = await fetchAllRecordsByYear(sensorIds, year);
        // storing all those record ids
        let recordIds = [];
        records.forEach((record) => recordIds.push(record.id));
        recordIds = [...new Set(recordIds)];
        // fetching all records according to the record id's (extra step)
        let recordsByYear = await fetchRecordsByYear(year, recordIds);
        // initializing data objects for graph
        let monthlyTotalUsedEnergy = [];
        let monthlyUsedEnergy = [];
        let monthlyDates = [];
        let monthlyAmountOfShowersTaken = [];
        let monthlyTotalAmountOfLiters = [];
        let monthlyTotalTimeInShower = [];

        // creating a full array of months
        MONTHS_OPTIONS.forEach((month) => {
          monthlyTotalUsedEnergy.push(0);
          monthlyDates.push(month.name.substring(0, 3));
          monthlyAmountOfShowersTaken.push(0);
          monthlyUsedEnergy.push(0);
          monthlyTotalAmountOfLiters.push(0);
          monthlyTotalTimeInShower.push(0);
        });
        // if the response is not a string (meaning an error) then allocate index to value
        if (typeof recordsByYear !== 'string') {
          // loop through each month and set the data available to the corresponding index (0 => january)
          monthlyDates.forEach((date, index) => {
            recordsByYear.forEach((record) => {
              if (date === record.month.substring(0, 3)) {
                monthlyUsedEnergy[index] = Number(Math.abs(record.total_energy).toFixed());
                monthlyAmountOfShowersTaken[index] = Number(record.count);
                monthlyTotalAmountOfLiters[index] = Number(record.liters);
                monthlyTotalTimeInShower[index] = Number(record.duration);
              }
            });
          });
        } else {
          toast.warning(recordsByYear);
        }
        // sum of everything in the saved energy array
        monthlyTotalUsedEnergy = monthlyUsedEnergy.reduce((a, b) => {
          return a + b;
        }, 0);

        // setting the data
        setDates(monthlyDates);
        setTotalUsedEnergy(monthlyTotalUsedEnergy);
        setUsedEnergy(monthlyUsedEnergy);
        setAmountOfShowersTaken(monthlyAmountOfShowersTaken);
        setTotalAmountOfLiters(monthlyTotalAmountOfLiters);
        setTotalTimeInShower(monthlyTotalTimeInShower);
      }
    } catch (error) {
      console.log(error);
      return toast.error('Oops, er is iets misgegaan');
    }
  };

  return (
    <Box sx={{ p: 3, pb: 1 }} dir="ltr">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Formik
            initialValues={{ ...INITIAL_FORM_STATE }}
            onSubmit={onSubmitForm}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize={false}
          >
            {(props) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h3" sx={{ fontWeight: 900 }}>
                      Inzicht in douchegedrag
                    </Typography>
                  </Grid>
                  {sensorOptions && sensorOptions.length > 0 && (
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        options={sensorOptions ?? []}
                        disabled={filter}
                        sx={{
                          '.Mui-disabled': {
                            opacity: '0.5',
                          },
                        }}
                        disableCloseOnSelect
                        onChange={(event, value) => (props.values.sensors = value)}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecteer specifieke sensors"
                            name="sensors"
                            placeholder="Selecteer specifieke sensors"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {loading ? (
                    <Box sx={{ p: 3, pb: 1 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Grid item xs={12}>
                      <Checkbox
                        checked={filter}
                        onChange={handleCheckedFilter}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      {`${filter ? 'Verberg filteropties' : 'Toon filteropties'}`}
                    </Grid>
                  )}

                  {/* FILTER AREA */}
                  {sensorOptions && sensorOptions.length > 0 && filter && (
                    <>
                      <Grid item xs={12}>
                        <Divider>
                          <Chip label="FILTER OPTIES" sx={{ fontWeight: 900 }} color="primary" />
                        </Divider>
                      </Grid>
                      {/* Groups */}
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          options={groupsOptions ?? []}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.groups = value)}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Groep(en)" name="groups" placeholder="Groep(en)" />
                          )}
                        />
                      </Grid>
                      {/* Projects */}
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          options={projectsOptions ?? []}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.projects = value)}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Project(en)" name="projects" placeholder="Project(en)" />
                          )}
                        />
                      </Grid>
                      {/* DWTW */}
                      <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                          multiple
                          options={dwtws ?? []}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.dwtw = value)}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="DWTW" name="dwtw" placeholder="DWTW" />
                          )}
                        />
                      </Grid>
                      {/* household */}
                      <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                          multiple
                          options={households ?? []}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.households = value)}
                          getOptionLabel={(option) => String(option.household)}
                          isOptionEqualToValue={(option, value) => option.household === value.household}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.household}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Grootte huishouden"
                              name="households"
                              placeholder="Grootte huishouden"
                            />
                          )}
                        />
                      </Grid>
                      {/* tapwatergenerator */}
                      <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                          multiple
                          options={TAPWATERGENERATOR_OPTIONS}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.tapwatergenerators = value)}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Type tapwateropwekker"
                              name="tapwatergenerators"
                              placeholder="Type tapwateropwekker"
                            />
                          )}
                        />
                      </Grid>
                      {/* residence */}
                      <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                          multiple
                          options={RESIDENCE_OPTIONS}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.residences = value)}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Type woning" name="residences" placeholder="Type woning" />
                          )}
                        />
                      </Grid>
                      {/* city */}
                      <Grid item xs={12} md={12} lg={12}>
                        <Autocomplete
                          multiple
                          options={cities ?? []}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.cities = value)}
                          getOptionLabel={(option) => option.city}
                          isOptionEqualToValue={(option, value) => option.city === value.city}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.city}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Woonplaats" name="cities" placeholder="Woonplaats" />
                          )}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} md={6} lg={12}>
                    <SelectUI name="timeline" label="Selecteer tijdlijn" options={TIMELINE_OPTIONS} />
                  </Grid>

                  {props.values.timeline === 'Dag' && (
                    <>
                      <Grid item xs={12} md={3} lg={3}>
                        <SelectUI
                          name="day"
                          label="Selecteer dag"
                          options={Array(getDaysInMonth(new Date(props.values.year, props.values.month - 1)) + 1)
                            .fill(null)
                            .map((_, index) => ({ id: index, name: index }))}
                        />
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <SelectUI name="month" label="Selecteer maand" options={MONTHS_OPTIONS} />
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <SelectUI name="year" label="Selecteer jaar" options={years ?? []} />
                      </Grid>
                    </>
                  )}

                  {props.values.timeline === 'Maand' && (
                    <>
                      <Grid item xs={12} md={6} lg={6}>
                        <SelectUI name="month" label="Selecteer maand" options={MONTHS_OPTIONS} />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <SelectUI name="year" label="Selecteer jaar" options={years ?? []} />
                      </Grid>
                    </>
                  )}

                  {props.values.timeline === 'Jaar' && (
                    <Grid item xs={12} md={6} lg={12}>
                      {(props.values.month = '')}
                      <SelectUI name="year" label="Selecteer jaar" options={years ?? []} />
                    </Grid>
                  )}

                  <Grid item xs={12} md={4} lg={4}>
                    <Button>{props.isSubmitting ? <CircularProgress /> : 'Bekijk resultaten'}</Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>

        {/* graph */}
        {dates && dates.length !== 0 && !loading ? (
          <Grid item xs={12}>
            {dates.length === 12 ? (
              <Graph
                title="Energie- en waterverbruik"
                chartLabels={dates}
                chartData={[
                  {
                    name: 'Hoeveelheid verbruikte energie (kJ)',
                    type: 'column',
                    fill: 'solid',
                    opacity: 1,
                    data: usedEnergy,
                  },
                  {
                    name: 'Hoeveelheid verbruikt water (liters)',
                    type: 'column',
                    fill: 'solid',
                    opacity: 1,
                    data: totalAmountOfLiters,
                  },
                  {
                    name: 'Aantal douchebeurten',
                    type: 'column',
                    fill: 'solid',
                    // opacity: 0,
                    visible: false,
                    data: amountOfShowersTaken,
                  },
                  {
                    name: 'Totale doucheduur (minuten)',
                    type: 'column',
                    fill: 'solid',
                    opacity: 0,
                    data: totalTimeInShower,
                  },
                ]}
                yaxisOptions={[
                  {
                    axisTicks: {
                      show: true,
                    },
                    labels: {
                      formatter: function (value) {
                        return `${String(value)
                          .split('')
                          .reverse()
                          .join('')
                          .replace(/(\d{3}\B)/g, '$1.')
                          .split('')
                          .reverse()
                          .join('')} kJ`;
                      },
                      style: {
                        colors: '#00AEDF',
                      },
                    },
                    title: {
                      style: {
                        color: '#00AEDF',
                      },
                    },
                  },
                  {
                    opposite: true,
                    axisTicks: {
                      show: true,
                    },
                    labels: {
                      formatter: function (value) {
                        return `${String(value)
                          .split('')
                          .reverse()
                          .join('')
                          .replace(/(\d{3}\B)/g, '$1.')
                          .split('')
                          .reverse()
                          .join('')} L`;
                      },
                      style: {
                        colors: '#2562c4',
                      },
                    },
                  },
                ]}
                subheader={undefined}
              />
            ) : (
              <Graph
                title="Energie- en waterverbruik"
                chartLabels={dates}
                chartData={[
                  {
                    name: 'Hoeveelheid verbruikte energie (kJ)',
                    type: 'column',
                    fill: 'solid',
                    opacity: 1,
                    data: usedEnergy,
                  },
                  {
                    name: 'Hoeveelheid verbruikt water (liters)',
                    type: 'column',
                    fill: 'solid',
                    opacity: 1,
                    data: totalAmountOfLiters,
                  },
                  {
                    name: 'Aantal douchebeurten',
                    type: 'column',
                    fill: 'solid',
                    // opacity: 0,
                    visible: false,
                    data: amountOfShowersTaken,
                  },
                  {
                    name: 'Totale doucheduur (minuten)',
                    type: 'column',
                    fill: 'solid',
                    opacity: 0,
                    data: totalTimeInShower,
                  },
                ]}
                yaxisOptions={[
                  {
                    axisTicks: {
                      show: true,
                    },
                    labels: {
                      formatter: function (value) {
                        return `${String(value)
                          .split('')
                          .reverse()
                          .join('')
                          .replace(/(\d{3}\B)/g, '$1.')
                          .split('')
                          .reverse()
                          .join('')} kJ`;
                      },
                      style: {
                        colors: '#00AEDF',
                      },
                    },
                    title: {
                      style: {
                        color: '#00AEDF',
                      },
                    },
                  },
                  {
                    opposite: true,
                    axisTicks: {
                      show: true,
                    },
                    labels: {
                      formatter: function (value) {
                        return `${String(value)
                          .split('')
                          .reverse()
                          .join('')
                          .replace(/(\d{3}\B)/g, '$1.')
                          .split('')
                          .reverse()
                          .join('')} L`;
                      },
                      style: {
                        colors: '#2562c4',
                      },
                    },
                  },
                ]}
                subheader={undefined}
              />
            )}
          </Grid>
        ) : (
          <Box sx={{ p: 3, pb: 1 }}>
            <CircularProgress />
          </Box>
        )}

        {/* cards below graph */}
        {dates && dates.length !== 0 && !loading ? (
          <>
            <Grid item xs={12} md={4} lg={4}>
              <Card
                sx={{
                  backgroundColor: '#00AEDF',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  boxShadow: 0,
                  justifyContent: 'center',
                  gap: '1rem',
                }}
              >
                <Box sx={{ p: 3 }} dir="ltr">
                  <Typography variant="subtitle1" sx={{ fontWeight: 900, color: '#fff', fontSize: '.9rem' }}>
                    Verbruikte energie (totaal)
                  </Typography>
                  <Typography variant="h3" sx={{ color: '#fff' }}>
                    {String(totalUsedEnergy)
                      .split('')
                      .reverse()
                      .join('')
                      .replace(/(\d{3}\B)/g, '$1.')
                      .split('')
                      .reverse()
                      .join('')}{' '}
                    kJ
                  </Typography>
                </Box>
                <Box sx={{ p: 3 }}>
                  <Typography sx={{ color: '#fff' }}>{getIcon('eva:flash-outline')}</Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Card
                sx={{
                  backgroundColor: '#2562C4',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  boxShadow: 0,
                  justifyContent: 'center',
                  gap: '1rem',
                }}
              >
                <Box sx={{ p: 3 }} dir="ltr">
                  <Typography variant="subtitle1" sx={{ fontWeight: 900, color: '#fff', fontSize: '.9rem' }}>
                    Verbruikt water (totaal)
                  </Typography>
                  <Typography variant="h3" sx={{ color: '#fff' }}>
                    {String(Math.abs(totalAmountOfLiters.reduce((a, b) => a + b)).toFixed())
                      .split('')
                      .reverse()
                      .join('')
                      .replace(/(\d{3}\B)/g, '$1.')
                      .split('')
                      .reverse()
                      .join('')}{' '}
                    L
                  </Typography>
                </Box>
                <Box sx={{ p: 3 }}>
                  <Typography sx={{ color: '#fff' }}>{getIcon('eva:droplet-outline')}</Typography>
                </Box>
              </Card>
            </Grid>
          </>
        ) : (
          <Box sx={{ p: 3, pb: 1 }}>
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </Box>
  );
}
