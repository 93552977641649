import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from './Auth';
import { validate } from 'src/services/fetchApi';

export const RequireAuth = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  validate().then((response) => {
    if (response.status === 200) {
      auth.isLoggedIn(true);
    } else {
      auth.isLoggedIn(false);
    }
  });

  if (!auth.isAuthenticated) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return children;
};
