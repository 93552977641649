import { Grid, Container, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from 'src/components/Iconify';

import Page from 'src/components/Page';
import { SearchInput } from 'src/sections/@dashboard/app/SearchInput';
import { deleteSensor, fetchAllSensors, fetchAllSensorsFiltered } from 'src/services/fetchApi';

const CheckIcon = () => <Iconify icon="eva:checkmark-fill" width={22} height={22} sx={undefined} color="green" />;
const XIcon = () => <Iconify icon="eva:close-fill" width={22} height={22} sx={undefined} color="gray" />;

export function Sensors() {
  const [searchValue, setSearchValue] = useState('');
  const [sensors, setSensors] = useState<any[] | null>(null);

  const search = useCallback(async () => {
    setSensors(await fetchAllSensorsFiltered(searchValue));
  }, [searchValue]);

  const _deleteSensor = useCallback(
    async (id: number, name: string) => {
      if (window.confirm(`Weet je zeker dat je de sensor(set) "${name}" wilt verwijderen?`)) {
        const result = await deleteSensor(id);

        if (result.status === 200) {
          toast.success(`Sensor(set) "${name}" verwijderd`);
        } else {
          toast.error(`Er ging iets fout bij het verwijderen van de sensor(set) "${name}". Probeer het later nog eens`);
        }

        await search();
      }
    },
    [search]
  );

  useEffect(() => {
    async function fetch() {
      setSensors(await fetchAllSensors());
    }

    fetch();
  }, []);

  return (
    <Page title="Sensors">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ mb: 3 }} style={{ display: 'flex', justifyContent: 'space-between' }}>
              Sensors
              <Link to="/dashboard/add-sensor">
                <Button variant="contained" color="primary" onClick={null}>
                  <Iconify icon="eva:plus-outline" sx={{ mr: 1 }} width={22} height={22} />
                  Sensor toevoegen
                </Button>
              </Link>
            </Typography>

            <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} search={search} />

            <div style={{ overflow: 'auto', maxWidth: '100vw' }}>
              {sensors ? (
                sensors.length === 0 ? (
                  <Typography style={{ marginTop: '1rem' }} variant="body1">
                    Geen sensors gevonden
                  </Typography>
                ) : (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Naam</TableCell>
                        <TableCell>Set</TableCell>
                        <TableCell>Sensor ID(s)</TableCell>
                        <TableCell>Project</TableCell>
                        <TableCell>Groep</TableCell>
                        <TableCell>DWTW</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {sensors.map((sensor) => (
                        <TableRow key={sensor.id}>
                          <TableCell>{sensor.id}</TableCell>
                          <TableCell>{sensor.name}</TableCell>
                          <TableCell>{sensor.isSet ? <CheckIcon /> : <XIcon />}</TableCell>
                          <TableCell>
                            {sensor.isSet ? (
                              <ul style={{ wordBreak: 'break-all', minWidth: '10rem' }}>
                                <li>{sensor.sensorSet?.idSensor1}</li>
                                <li>{sensor.sensorSet?.idSensor2}</li>
                              </ul>
                            ) : (
                              sensor.idSensor1 ?? '-'
                            )}
                          </TableCell>
                          <TableCell>{sensor.project?.name ?? '-'}</TableCell>
                          <TableCell>{sensor.group?.name ?? '-'}</TableCell>
                          <TableCell>{sensor.dwtw?.name ?? '-'}</TableCell>
                          <TableCell width={'4rem'}>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => _deleteSensor(sensor.id, sensor.name)}
                            >
                              <Iconify icon="eva:trash-2-outline" width={22} height={22} sx={undefined} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )
              ) : (
                <Iconify icon="eos-icons:loading" sx={undefined} width={40} height={40} />
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
