import React from 'react';
import { Button } from '@mui/material';
import { useFormikContext } from 'formik';

const ButtonField = ({ disabled, children }: { disabled?: boolean; children: React.ReactNode }) => {
  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  const configButtonField = {
    onClick: handleSubmit,
    variant: 'contained',
    fullWidth: true,
    color: 'primary',
  };

  return (
    <Button
      disabled={disabled}
      sx={{ p: 1.5, fontSize: '.9rem', textTransform: 'none' }}
      {...(configButtonField as any)}
    >
      {children}
    </Button>
  );
};

export default ButtonField;
