import Router from 'src/routes';
import ThemeProvider from 'src/theme';
import { BaseOptionChartStyle } from 'src/components/chart/BaseOptionChart';
import { ToastContainer } from 'react-toastify';
import React from 'react';

export default function App() {
  return (
    <ThemeProvider>
      <ToastContainer
        position={'top-right'}
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={false}
        draggable={false}
      />
      <BaseOptionChartStyle />
      <Router />
    </ThemeProvider>
  );
}
