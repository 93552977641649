import Graph from './Chart';
import Iconify from 'src/components/Iconify';
import 'react-toastify/dist/ReactToastify.css';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Select as SelectUI, TextField } from 'src/components/FormsUI';
import { MONTHS_OPTIONS, TIMELINE_OPTIONS, RESIDENCE_OPTIONS, TAPWATERGENERATOR_OPTIONS } from 'src/utils/selectItems';
import {
  fetchAllSensorSets,
  fetchAllGroups,
  fetchAllProjects,
  fetchAllCities,
  filterFetchSensorsSets,
  fetchAllRecordsByYear,
  fetchSensorSetRecordsByYear,
  fetchAllRecordsByYearMonth,
  fetchSensorSetRecordsByMonth,
  fetchSelectedSensorSet,
  fetchAllDWTWs,
  fetchAllHouseHolds,
  fetchSensorSetRecordsByDay,
  fetchAllRecordsByDay,
  fetchAllYears,
} from 'src/services/fetchApi';
import { Typography, Grid, Card, CircularProgress, Box, Autocomplete, Chip, Checkbox, Divider } from '@mui/material';
import getDaysInMonth from 'date-fns/getDaysInMonth';

export default function EnergySensorList() {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const today = new Date();
  const currentYear = today.getFullYear();
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [daysInSelectedMonth, setDaysInSelectedMonth] = useState<number>();
  const [groupsOptions, setGroupsOptions] = useState();
  const [projectsOptions, setProjectsOptions] = useState();
  const [cities, setCities] = useState();
  const [dwtws, setDWTWs] = useState();
  const [years, setYears] = useState();
  const [households, setHouseHolds] = useState();
  const [sensorsetOptions, setSensorsetOptions] = useState([]);
  const [dates, setDates] = useState([]);
  const [savedEnergy, setSavedEnergy] = useState([]);
  const [amountOfShowersTaken, setAmountOfShowersTaken] = useState([]);
  const [totalTimeInShower, setTotalTimeInShower] = useState([]);
  const [totalSavedEnergy, setTotalSavedEnergy] = useState<number>();
  const [totalSavedEnergyByEachSet, setTotalSavedEnergyByEachSet] = useState([]);

  const getIcon = (name) => <Iconify icon={name} width={60} height={60} sx={undefined} />;

  // initial form state
  const INITIAL_FORM_STATE = useMemo(
    () => ({
      sensorset: [],
      timeline: 'Jaar',
      day: '',
      month: '',
      year: currentYear,
      groups: [],
      projects: [],
      dwtw: [],
      tapwatergenerators: [],
      residences: [],
      households: [],
      cities: [],
    }),
    [currentYear]
  );

  useEffect(() => {
    const fetch = async () => {
      setSensorsetOptions(await fetchAllSensorSets());
      setGroupsOptions(await fetchAllGroups());
      setProjectsOptions(await fetchAllProjects());
      setCities(await fetchAllCities());
      setDWTWs(await fetchAllDWTWs());
      setHouseHolds(await fetchAllHouseHolds());
      setYears(await fetchAllYears());
      await onSubmitForm(INITIAL_FORM_STATE);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckedFilter = (event) => {
    setFilter(event.target.checked);
  };

  const onSubmitForm = async (values) => {
    try {
      // from form receive timeline, month, year and sensorset (optional)
      let { timeline, day, month, year, sensorset } = values;
      // get days in the selected month, 07 => 31 days in july
      setDaysInSelectedMonth(new Date(year, month, 0).getTime());
      // fallback if left empty, return error
      if (timeline === '' && values.sensorset === '') {
        return toast.error('Selecteer alle velden om data in te zien');
      }
      // if the timeline is month and no filter is selected
      if (!filter && timeline === 'Dag' && day && month && sensorset.length > 0 && year) {
        // init sensor id array
        let sensorIds = [];
        // loop through the array of sensorset and fetch the sensorset id's
        for (let i = 0; i < sensorset.length; i++) {
          let response = await fetchSelectedSensorSet(sensorset[i].name);
          sensorIds.push(response.sensorSet.idSensor1, response.sensorSet.idSensor2);
        }
        // fetching all records on the selected dayMonthYear (2022-07-09) -> this returns an array of records (amount of showers taken)
        const records = await fetchAllRecordsByDay(sensorIds, day, month, year);
        // storing all those record ids
        let sensorSetRecordIds = [];
        let recordIds = [];
        records.forEach((record) => {
          sensorSetRecordIds.push(record.sensorSetRecord.id);
          recordIds.push(record.id);
        });

        // initializing data objects for graph
        let hourlyTotalSavedEnergy;
        let hourlyTotalSavedEnergyByEachSet = [];
        let hourlySavedEnergy = [];
        let hourlyDates = [];
        let hourlyAmountOfShowersTaken = [];
        let hourlyTotalTimeInShower = [];
        // creating a full array of hours in a day. This is to populate empty data
        for (let i = 0; i < 24; i++) {
          hourlyTotalSavedEnergyByEachSet.push(0);
          hourlyDates.push(`${(i && i <= 9) || i === 0 ? '0' + i : i}`);
          hourlyAmountOfShowersTaken.push(0);
          hourlySavedEnergy.push(0);
          hourlyTotalTimeInShower.push(0);
        }

        // fetch records
        let sensorSetRecords = await fetchSensorSetRecordsByDay(sensorSetRecordIds);
        if (typeof sensorSetRecords !== 'string') {
          hourlyDates.forEach((hour, index) => {
            sensorSetRecords.forEach((sensorSetRecord) => {
              let hourString = `${
                new Date(sensorSetRecord.time).getHours() <= 9
                  ? '0' + new Date(sensorSetRecord.time).getHours()
                  : new Date(sensorSetRecord.time).getHours()
              }`;
              if (hour === hourString) {
                hourlyTotalTimeInShower[index] = Number(sensorSetRecord.duration);
                hourlySavedEnergy[index] = Number(Math.abs(sensorSetRecord.sum).toFixed());
                hourlyAmountOfShowersTaken[index] = Number(sensorSetRecord.count);
                hourlyTotalSavedEnergyByEachSet[index] = Number(sensorSetRecord.sum).toFixed();
              }
            });
          });
        } else {
          toast.warning(sensorSetRecords);
        }

        // sum of everything in the saved energy array
        hourlyTotalSavedEnergy = hourlySavedEnergy.reduce((a, b) => {
          return a + b;
        }, 0);

        // setting the data
        setDates(hourlyDates);
        setTotalSavedEnergy(hourlyTotalSavedEnergy);
        setTotalSavedEnergyByEachSet(hourlyTotalSavedEnergyByEachSet);
        setSavedEnergy(hourlySavedEnergy);
        setAmountOfShowersTaken(hourlyAmountOfShowersTaken);
        setTotalTimeInShower(hourlyTotalTimeInShower);
      }
      // if the timeline is month and no filter is selected
      if (!filter && timeline === 'Maand' && month && sensorset.length > 0 && year) {
        // init sensor id array
        let sensorIds = [];
        // loop through the array of sensorset and fetch the sensorset id's
        for (let i = 0; i < sensorset.length; i++) {
          let response = await fetchSelectedSensorSet(sensorset[i].name);
          sensorIds.push(response.sensorSet.idSensor1, response.sensorSet.idSensor2);
        }
        // fetch all records of all id's that have been pulled before
        const records = await fetchAllRecordsByYearMonth(sensorIds, month, year);
        let sensorSetRecordIds = [];
        // for each record, we want to get the sensorsetrecord id, but many duplicates so convert to Set
        records.forEach((record) => sensorSetRecordIds.push(record.sensorSetRecordId));
        sensorSetRecordIds = [...new Set(sensorSetRecordIds)];
        // fetch the data that gets displayed
        let sensorSetRecords = await fetchSensorSetRecordsByMonth(sensorSetRecordIds);
        // initializing data objects for graph
        let dailyTotalSavedEnergy = 0;
        let dailyTotalSavedEnergyByEachSet = [];
        let dailySavedEnergy = [];
        let dailyDates = [];
        let dailyAmountOfShowersTaken = [];

        // loop through the days of the month and initialize all arrays of data
        for (let i = 1; i < daysInSelectedMonth + 1; i++) {
          dailyDates.push(`${i < 10 ? '0' + i : i}`);
          dailyAmountOfShowersTaken.push(0);
          dailySavedEnergy.push(0);
          dailyTotalSavedEnergyByEachSet.push(0);
        }
        // converting the date from 2022/07/04 to europe version 04/07/2022
        if (typeof sensorSetRecords === 'object' && sensorSetRecords.length > 0) {
          sensorSetRecords.map((sensorSetRecord) => {
            return (sensorSetRecord.date = new Date(sensorSetRecord.date).toLocaleDateString('en-GB'));
          });
          // set data for the corresponding index of the data and the day it happened
          dailyDates.forEach((date, index) => {
            sensorSetRecords.forEach((sensorSetRecord) => {
              if (date === sensorSetRecord.date.substring(0, 2)) {
                dailySavedEnergy[index] = Number(Math.abs(sensorSetRecord.sum).toFixed());
                dailyAmountOfShowersTaken[index] = Number(sensorSetRecord.count);
                dailyTotalSavedEnergyByEachSet[index] =
                  Number(Math.abs(sensorSetRecord.sum).toFixed()) / sensorset.length;
              }
            });
          });
          // sum of all energy saved on that day.
          dailyTotalSavedEnergy = dailySavedEnergy.reduce((a, b) => {
            return a + b;
          }, 0);
        } else {
          toast.warn(sensorSetRecords);
        }
        // setting the data
        setDates(dailyDates);
        setSavedEnergy(dailySavedEnergy);
        setAmountOfShowersTaken(dailyAmountOfShowersTaken);
        setTotalSavedEnergy(dailyTotalSavedEnergy);
        setTotalSavedEnergyByEachSet(dailyTotalSavedEnergyByEachSet);
      }
      // if the timeline is year and no filter is selected
      if (!filter && timeline === 'Jaar' && sensorset.length > 0 && year) {
        let sensorIds = [];

        for (let i = 0; i < sensorset.length; i++) {
          let response = await fetchSelectedSensorSet(sensorset[i].name);
          sensorIds.push(response.sensorSet.idSensor1, response.sensorSet.idSensor2);
        }

        const records = await fetchAllRecordsByYear(sensorIds, year);
        let sensorSetRecordIds = [];
        let recordIds = [];
        records.forEach((record) => {
          sensorSetRecordIds.push(record.sensorSetRecordId);
          recordIds.push(record.id);
        });
        sensorSetRecordIds = [...new Set(sensorSetRecordIds)];

        // initializing data objects for graph
        let monthlyTotalSavedEnergy;
        let monthlyTotalSavedEnergyByEachSet = [];
        let monthlySavedEnergy = [];
        let monthlyDates = [];
        let monthlyAmountOfShowersTaken = [];
        let monthlyTotalTimeInShower = [];

        // creating a full array of months
        MONTHS_OPTIONS.forEach((month) => {
          monthlyTotalSavedEnergyByEachSet.push(0);
          monthlyDates.push(month.name.substring(0, 3));
          monthlyAmountOfShowersTaken.push(0);
          monthlySavedEnergy.push(0);
          monthlyTotalTimeInShower.push(0);
        });

        let sensorSetRecords = await fetchSensorSetRecordsByYear(year, sensorSetRecordIds);
        if (typeof sensorSetRecords !== 'string') {
          // loop through each month and set the data available to the corresponding index (0 => january)
          monthlyDates.forEach((date, index) => {
            sensorSetRecords.forEach((sensorSetRecord) => {
              if (date === sensorSetRecord.month.substring(0, 3)) {
                monthlyTotalTimeInShower[index] = Number(sensorSetRecord.duration);
                monthlySavedEnergy[index] = Number(Math.abs(sensorSetRecord.sum).toFixed());
                monthlyAmountOfShowersTaken[index] = Number(sensorSetRecord.count);
                monthlyTotalSavedEnergyByEachSet[index] =
                  Number(Math.abs(sensorSetRecord.sum).toFixed()) / sensorset.length;
              }
            });
          });
        } else {
          toast.warning(sensorSetRecords);
        }
        monthlyTotalSavedEnergy = monthlySavedEnergy.reduce((a, b) => {
          return a + b;
        }, 0);

        // setting the data
        setDates(monthlyDates);
        setTotalSavedEnergy(monthlyTotalSavedEnergy);
        setSavedEnergy(monthlySavedEnergy);
        setAmountOfShowersTaken(monthlyAmountOfShowersTaken);
        setTotalTimeInShower(monthlyTotalTimeInShower);
        setTotalSavedEnergyByEachSet(monthlyTotalSavedEnergyByEachSet);
      }
      // if the filter search is used and the timeline selected is daily
      if (filter && timeline === 'Dag' && day && month && year) {
        // init variables
        let { groups, projects, dwtw, tapwatergenerators, cities, residences, households } = values;
        let groupsArr = [];
        let projectsArr = [];
        let dwtwArr = [];
        let tapwatergeneratorsArr = [];
        let residencesArr = [];
        let householdsArr = [];
        let citiesArr = [];

        // ** Need to find a way to simplify this, all form values are pushed in the array as objects
        if (groups && groups.length > 0) {
          groups.forEach((group) => {
            groupsArr.push(group.id);
          });
        }

        if (projects && projects.length > 0) {
          projects.forEach((project) => {
            projectsArr.push(project.id);
          });
        }

        if (dwtw && dwtw.length > 0) {
          dwtw.forEach((dwtw) => {
            dwtwArr.push(dwtw.id);
          });
        }

        if (tapwatergenerators && tapwatergenerators.length > 0) {
          tapwatergenerators.forEach((tapwatergenerator) => {
            tapwatergeneratorsArr.push(tapwatergenerator.name);
          });
        }

        if (residences && residences.length > 0) {
          residences.forEach((residence) => {
            residencesArr.push(residence.name);
          });
        }

        if (households && households.length > 0) {
          households.forEach((household) => {
            householdsArr.push(Number(household.household));
          });
        }

        if (cities && cities.length > 0) {
          cities.forEach((city) => {
            citiesArr.push(city.city);
          });
        }

        let sensorIds = [];
        // fetch sensors that match the filter
        const sensors = await filterFetchSensorsSets(
          groupsArr,
          projectsArr,
          dwtwArr,
          tapwatergeneratorsArr,
          citiesArr,
          residencesArr,
          householdsArr
        );
        sensors.forEach((sensor) => {
          sensorIds.push(sensor.sensorSet.idSensor1, sensor.sensorSet.idSensor2);
        });
        // fetching all records on the selected dayMonthYear (2022-07-09) -> this returns an array of records (amount of showers taken)
        const records = await fetchAllRecordsByDay(sensorIds, day, month, year);
        // storing all those record ids
        let sensorSetRecordIds = [];
        let recordIds = [];
        records.forEach((record) => {
          sensorSetRecordIds.push(record.sensorSetRecord.id);
          recordIds.push(record.id);
        });

        // initializing data objects for graph
        let hourlyTotalSavedEnergy;
        let hourlyTotalSavedEnergyByEachSet = [];
        let hourlySavedEnergy = [];
        let hourlyDates = [];
        let hourlyAmountOfShowersTaken = [];
        let hourlyTotalTimeInShower = [];
        // creating a full array of hours in a day. This is to populate empty data
        for (let i = 0; i < 24; i++) {
          hourlyTotalSavedEnergyByEachSet.push(0);
          hourlyDates.push(`${(i && i <= 9) || i === 0 ? '0' + i : i}`);
          hourlyAmountOfShowersTaken.push(0);
          hourlySavedEnergy.push(0);
          hourlyTotalTimeInShower.push(0);
        }

        // fetch records
        let sensorSetRecords = await fetchSensorSetRecordsByDay(sensorSetRecordIds);
        if (typeof sensorSetRecords !== 'string') {
          hourlyDates.forEach((hour, index) => {
            sensorSetRecords.forEach((sensorSetRecord) => {
              let hourString = `${
                new Date(sensorSetRecord.time).getHours() <= 9
                  ? '0' + new Date(sensorSetRecord.time).getHours()
                  : new Date(sensorSetRecord.time).getHours()
              }`;
              if (hour === hourString) {
                hourlyTotalTimeInShower[index] = Number(sensorSetRecord.duration);
                hourlySavedEnergy[index] = Number(Math.abs(sensorSetRecord.sum).toFixed());
                hourlyAmountOfShowersTaken[index] = Number(sensorSetRecord.count);
                hourlyTotalSavedEnergyByEachSet[index] = Number(sensorSetRecord.sum).toFixed();
              }
            });
          });
        } else {
          toast.warning(sensorSetRecords);
        }

        // sum of everything in the saved energy array
        hourlyTotalSavedEnergy = hourlySavedEnergy.reduce((a, b) => {
          return a + b;
        }, 0);

        // setting the data
        setDates(hourlyDates);
        setTotalSavedEnergy(hourlyTotalSavedEnergy);
        setTotalSavedEnergyByEachSet(hourlyTotalSavedEnergyByEachSet);
        setSavedEnergy(hourlySavedEnergy);
        setAmountOfShowersTaken(hourlyAmountOfShowersTaken);
        setTotalTimeInShower(hourlyTotalTimeInShower);
      }
      // if the timeline is month and filter is selected
      if (filter && timeline === 'Maand' && month && year) {
        // init variables
        let sensorIds = [];
        let { groups, projects, dwtw, tapwatergenerators, cities, residences, households } = values;
        let groupsArr = [];
        let projectsArr = [];
        let dwtwArr = [];
        let tapwatergeneratorsArr = [];
        let residencesArr = [];
        let householdsArr = [];
        let citiesArr = [];

        // ** Need to find a way to simplify this, all form values are pushed in the array as objects
        if (groups && groups.length > 0) {
          groups.forEach((group) => {
            groupsArr.push(group.id);
          });
        }

        if (projects && projects.length > 0) {
          projects.forEach((project) => {
            projectsArr.push(project.id);
          });
        }

        if (dwtw && dwtw.length > 0) {
          dwtw.forEach((dwtw) => {
            dwtwArr.push(dwtw.id);
          });
        }

        if (tapwatergenerators && tapwatergenerators.length > 0) {
          tapwatergenerators.forEach((tapwatergenerator) => {
            tapwatergeneratorsArr.push(tapwatergenerator.name);
          });
        }

        if (residences && residences.length > 0) {
          residences.forEach((residence) => {
            residencesArr.push(residence.name);
          });
        }

        if (households && households.length > 0) {
          households.forEach((household) => {
            householdsArr.push(Number(household.name));
          });
        }

        if (cities && cities.length > 0) {
          cities.forEach((city) => {
            citiesArr.push(city.city);
          });
        }

        // fetch sensors that match the filter
        const sensors = await filterFetchSensorsSets(
          groupsArr,
          projectsArr,
          dwtwArr,
          tapwatergeneratorsArr,
          citiesArr,
          residencesArr,
          householdsArr
        );
        sensors.forEach((sensor) => {
          sensorIds.push(sensor.sensorSet.idSensor1, sensor.sensorSet.idSensor2);
        });

        // fetch all records by the previously fetched sensors
        const records = await fetchAllRecordsByYearMonth(sensorIds, month, year);
        let sensorSetRecordIds = [];
        // each record has a sensorset id which gets pushed in the sensorsetrecordids array
        records.forEach((record) => sensorSetRecordIds.push(record.sensorSetRecordId));
        sensorSetRecordIds = [...new Set(sensorSetRecordIds)];
        // use these sensorsetrecordids to fetch the aggregated data in the backend by sensorsetrecord id
        let sensorSetRecords = await fetchSensorSetRecordsByMonth(sensorSetRecordIds);
        // initializing data objects for graph
        let dailyTotalSavedEnergy = 0;
        let dailyTotalSavedEnergyByEachSet = [];
        let dailySavedEnergy = [];
        let dailyDates = [];
        let dailyAmountOfShowersTaken = [];
        let dailyTotalTimeInShower = [];

        // init default array values for graph
        for (let i = 1; i < daysInSelectedMonth + 1; i++) {
          dailyTotalSavedEnergyByEachSet.push(0);
          dailyDates.push(`${i < 10 ? '0' + i : i}`);
          dailyAmountOfShowersTaken.push(0);
          dailySavedEnergy.push(0);
          dailyTotalTimeInShower.push(0);
        }
        // converting the date from 2022/07/04 to europe version 04/07/2022
        if (typeof sensorSetRecords === 'object' && sensorSetRecords.length > 0) {
          sensorSetRecords.map((sensorSetRecord) => {
            return (sensorSetRecord.date = new Date(sensorSetRecord.date).toLocaleDateString('en-GB'));
          });
          // looping thorugh each date and record to find corresponding dates, then assign values to index
          dailyDates.forEach((date, index) => {
            sensorSetRecords.forEach((sensorSetRecord) => {
              if (date === sensorSetRecord.date.substring(0, 2)) {
                dailyTotalTimeInShower[index] = Number(sensorSetRecord.duration);
                dailySavedEnergy[index] = Number(Math.abs(sensorSetRecord.sum).toFixed());
                dailyAmountOfShowersTaken[index] = Number(sensorSetRecord.count);
                dailyTotalSavedEnergyByEachSet[index] = Number(sensorSetRecord.sum).toFixed();
              }
            });
          });

          dailyTotalSavedEnergy = dailySavedEnergy.reduce((a, b) => {
            return a + b;
          }, 0);
        } else {
          toast.warn(sensorSetRecords);
        }

        // setting the data
        setDates(dailyDates);
        setTotalSavedEnergy(dailyTotalSavedEnergy);
        setTotalSavedEnergyByEachSet(dailyTotalSavedEnergyByEachSet);
        setTotalTimeInShower(dailyTotalTimeInShower);
        setSavedEnergy(dailySavedEnergy);
        setAmountOfShowersTaken(dailyAmountOfShowersTaken);
      }
      // if the timeline is year and filter is selected
      if (filter && timeline === 'Jaar' && year) {
        // init variables
        let { groups, projects, dwtw, tapwatergenerators, cities, residences, households } = values;
        let groupsArr = [];
        let projectsArr = [];
        let dwtwArr = [];
        let tapwatergeneratorsArr = [];
        let residencesArr = [];
        let householdsArr = [];
        let citiesArr = [];

        // ** Need to find a way to simplify this, all form values are pushed in the array as objects
        if (groups && groups.length > 0) {
          groups.forEach((group) => {
            groupsArr.push(group.id);
          });
        }

        if (projects && projects.length > 0) {
          projects.forEach((project) => {
            projectsArr.push(project.id);
          });
        }

        if (dwtw && dwtw.length > 0) {
          dwtw.forEach((dwtw) => {
            dwtwArr.push(dwtw.id);
          });
        }

        if (tapwatergenerators && tapwatergenerators.length > 0) {
          tapwatergenerators.forEach((tapwatergenerator) => {
            tapwatergeneratorsArr.push(tapwatergenerator.name);
          });
        }

        if (residences && residences.length > 0) {
          residences.forEach((residence) => {
            residencesArr.push(residence.name);
          });
        }

        if (households && households.length > 0) {
          households.forEach((household) => {
            householdsArr.push(Number(household.household));
          });
        }

        if (cities && cities.length > 0) {
          cities.forEach((city) => {
            citiesArr.push(city.city);
          });
        }

        let sensorIds = [];
        // fetch sensors that match the filter
        const sensors = await filterFetchSensorsSets(
          groupsArr,
          projectsArr,
          dwtwArr,
          tapwatergeneratorsArr,
          citiesArr,
          residencesArr,
          householdsArr
        );
        sensors.forEach((sensor) => {
          sensorIds.push(sensor.sensorSet.idSensor1, sensor.sensorSet.idSensor2);
        });

        const records = await fetchAllRecordsByYear(sensorIds, year);
        let sensorSetRecordIds = [];
        let recordIds = [];
        records.forEach((record) => {
          sensorSetRecordIds.push(record.sensorSetRecordId);
          recordIds.push(record.id);
        });
        sensorSetRecordIds = [...new Set(sensorSetRecordIds)];

        // initializing data objects for graph
        let monthlyTotalSavedEnergy;
        let monthlyTotalSavedEnergyByEachSet = [];
        let monthlySavedEnergy = [];
        let monthlyDates = [];
        let monthlyAmountOfShowersTaken = [];
        let monthlyTotalTimeInShower = [];

        // creating a full array of months
        MONTHS_OPTIONS.forEach((month) => {
          monthlyTotalSavedEnergyByEachSet.push(0);
          monthlyDates.push(month.name.substring(0, 3));
          monthlyAmountOfShowersTaken.push(0);
          monthlySavedEnergy.push(0);
          monthlyTotalTimeInShower.push(0);
        });

        let sensorSetRecords = await fetchSensorSetRecordsByYear(year, sensorSetRecordIds);
        if (typeof sensorSetRecords !== 'string') {
          // loop through each month and set the data available to the corresponding index (0 => january)
          monthlyDates.forEach((date, index) => {
            sensorSetRecords.forEach((sensorSetRecord) => {
              if (date === sensorSetRecord.month.substring(0, 3)) {
                monthlyTotalTimeInShower[index] = Number(sensorSetRecord.duration);
                monthlySavedEnergy[index] = Number(Math.abs(sensorSetRecord.sum).toFixed());
                monthlyAmountOfShowersTaken[index] = Number(sensorSetRecord.count);
                monthlyTotalSavedEnergyByEachSet[index] =
                  Number(Math.abs(sensorSetRecord.sum).toFixed()) / sensors.length;
              }
            });
          });
        } else {
          toast.warning(sensorSetRecords);
        }
        monthlyTotalSavedEnergy = monthlySavedEnergy.reduce((a, b) => {
          return a + b;
        }, 0);

        // setting the data
        setDates(monthlyDates);
        setTotalSavedEnergy(monthlyTotalSavedEnergy);
        setTotalSavedEnergyByEachSet(monthlyTotalSavedEnergyByEachSet);
        setSavedEnergy(monthlySavedEnergy);
        setAmountOfShowersTaken(monthlyAmountOfShowersTaken);
        setTotalTimeInShower(monthlyTotalTimeInShower);
      }
      // if the filter is not used and no sensors are selected, still fetch data daily
      if (!filter && !sensorset.length && timeline === 'Dag' && day && month && year) {
        // init variables
        let sensorIds = [];

        // setting to empty to call all possible sensors.
        let groupsArr = [];
        let projectsArr = [];
        let dwtwArr = [];
        let tapwatergeneratorsArr = [];
        let residencesArr = [];
        let householdsArr = [];
        let citiesArr = [];
        // fetch sensors that match the filter
        const sensors = await filterFetchSensorsSets(
          groupsArr,
          projectsArr,
          dwtwArr,
          tapwatergeneratorsArr,
          citiesArr,
          residencesArr,
          householdsArr
        );
        sensors.forEach((sensor) => {
          sensorIds.push(sensor.sensorSet.idSensor1, sensor.sensorSet.idSensor2);
        });
        // fetching all records on the selected dayMonthYear (2022-07-09) -> this returns an array of records (amount of showers taken)
        const records = await fetchAllRecordsByDay(sensorIds, day, month, year);
        // storing all those record ids
        let sensorSetRecordIds = [];
        let recordIds = [];
        records.forEach((record) => {
          sensorSetRecordIds.push(record.sensorSetRecord.id);
          recordIds.push(record.id);
        });

        // initializing data objects for graph
        let hourlyTotalSavedEnergy;
        let hourlyTotalSavedEnergyByEachSet = [];
        let hourlySavedEnergy = [];
        let hourlyDates = [];
        let hourlyAmountOfShowersTaken = [];
        let hourlyTotalTimeInShower = [];
        // creating a full array of hours in a day. This is to populate empty data
        for (let i = 0; i < 24; i++) {
          hourlyTotalSavedEnergyByEachSet.push(0);
          hourlyDates.push(`${(i && i <= 9) || i === 0 ? '0' + i : i}`);
          hourlyAmountOfShowersTaken.push(0);
          hourlySavedEnergy.push(0);
          hourlyTotalTimeInShower.push(0);
        }

        // fetch records
        let sensorSetRecords = await fetchSensorSetRecordsByDay(sensorSetRecordIds);
        if (typeof sensorSetRecords !== 'string') {
          hourlyDates.forEach((hour, index) => {
            sensorSetRecords.forEach((sensorSetRecord) => {
              let hourString = `${
                new Date(sensorSetRecord.time).getHours() <= 9
                  ? '0' + new Date(sensorSetRecord.time).getHours()
                  : new Date(sensorSetRecord.time).getHours()
              }`;
              if (hour === hourString) {
                hourlyTotalTimeInShower[index] = Number(sensorSetRecord.duration);
                hourlySavedEnergy[index] = Number(Math.abs(sensorSetRecord.sum).toFixed());
                hourlyAmountOfShowersTaken[index] = Number(sensorSetRecord.count);
                hourlyTotalSavedEnergyByEachSet[index] = Number(sensorSetRecord.sum).toFixed();
              }
            });
          });
        } else {
          toast.warning(sensorSetRecords);
        }

        // sum of everything in the saved energy array
        hourlyTotalSavedEnergy = hourlySavedEnergy.reduce((a, b) => {
          return a + b;
        }, 0);

        // setting the data
        setDates(hourlyDates);
        setTotalSavedEnergy(hourlyTotalSavedEnergy);
        setTotalSavedEnergyByEachSet(hourlyTotalSavedEnergyByEachSet);
        setSavedEnergy(hourlySavedEnergy);
        setAmountOfShowersTaken(hourlyAmountOfShowersTaken);
        setTotalTimeInShower(hourlyTotalTimeInShower);
      }
      // if the filter is not used and no sensors are selected, still fetch data monthly
      if (!filter && !sensorset.length && timeline === 'Maand' && month && year) {
        // init variables
        let sensorIds = [];

        // setting to empty to call all possible sensors.
        let groupsArr = [];
        let projectsArr = [];
        let dwtwArr = [];
        let tapwatergeneratorsArr = [];
        let residencesArr = [];
        let householdsArr = [];
        let citiesArr = [];

        // fetch sensors that match the filter
        const sensors = await filterFetchSensorsSets(
          groupsArr,
          projectsArr,
          dwtwArr,
          tapwatergeneratorsArr,
          citiesArr,
          residencesArr,
          householdsArr
        );
        sensors.forEach((sensor) => {
          sensorIds.push(sensor.sensorSet.idSensor1, sensor.sensorSet.idSensor2);
        });

        // fetch all records by the previously fetched sensors
        const records = await fetchAllRecordsByYearMonth(sensorIds, month, year);
        let sensorSetRecordIds = [];
        // each record has a sensorset id which gets pushed in the sensorsetrecordids array
        records.forEach((record) => sensorSetRecordIds.push(record.sensorSetRecordId));
        sensorSetRecordIds = [...new Set(sensorSetRecordIds)];
        // use these sensorsetrecordids to fetch the aggregated data in the backend by sensorsetrecord id
        let sensorSetRecords = await fetchSensorSetRecordsByMonth(sensorSetRecordIds);
        // initializing data objects for graph
        let dailyTotalSavedEnergy = 0;
        let dailyTotalSavedEnergyByEachSet = [];
        let dailySavedEnergy = [];
        let dailyDates = [];
        let dailyAmountOfShowersTaken = [];
        let dailyTotalTimeInShower = [];

        // init default array values for graph
        for (let i = 1; i < daysInSelectedMonth + 1; i++) {
          dailyTotalSavedEnergyByEachSet.push(0);
          dailyDates.push(`${i < 10 ? '0' + i : i}`);
          dailyAmountOfShowersTaken.push(0);
          dailySavedEnergy.push(0);
          dailyTotalTimeInShower.push(0);
        }
        // converting the date from 2022/07/04 to europe version 04/07/2022
        if (typeof sensorSetRecords === 'object' && sensorSetRecords.length > 0) {
          sensorSetRecords.map((sensorSetRecord) => {
            return (sensorSetRecord.date = new Date(sensorSetRecord.date).toLocaleDateString('en-GB'));
          });
          // looping thorugh each date and record to find corresponding dates, then assign values to index
          dailyDates.forEach((date, index) => {
            sensorSetRecords.forEach((sensorSetRecord) => {
              if (date === sensorSetRecord.date.substring(0, 2)) {
                dailyTotalTimeInShower[index] = Number(sensorSetRecord.duration);
                dailySavedEnergy[index] = Number(Math.abs(sensorSetRecord.sum).toFixed());
                dailyAmountOfShowersTaken[index] = Number(sensorSetRecord.count);
                dailyTotalSavedEnergyByEachSet[index] = Number(sensorSetRecord.sum).toFixed();
              }
            });
          });

          dailyTotalSavedEnergy = dailySavedEnergy.reduce((a, b) => {
            return a + b;
          }, 0);
        } else {
          toast.warn(sensorSetRecords);
        }

        // setting the data
        setDates(dailyDates);
        setTotalSavedEnergy(dailyTotalSavedEnergy);
        setTotalSavedEnergyByEachSet(dailyTotalSavedEnergyByEachSet);
        setTotalTimeInShower(dailyTotalTimeInShower);
        setSavedEnergy(dailySavedEnergy);
        setAmountOfShowersTaken(dailyAmountOfShowersTaken);
      }
      // if the filter is not used and no sensors are selected, still fetch data yearly
      if (!filter && !sensorset.length && timeline === 'Jaar' && year) {
        // init variables
        let sensorIds = [];

        // setting to empty to call all possible sensors.
        let groupsArr = [];
        let projectsArr = [];
        let dwtwArr = [];
        let tapwatergeneratorsArr = [];
        let residencesArr = [];
        let householdsArr = [];
        let citiesArr = [];

        // fetch sensors that match the filter
        const sensors = await filterFetchSensorsSets(
          groupsArr,
          projectsArr,
          dwtwArr,
          tapwatergeneratorsArr,
          citiesArr,
          residencesArr,
          householdsArr
        );
        sensors.forEach((sensor) => {
          sensorIds.push(sensor.sensorSet.idSensor1, sensor.sensorSet.idSensor2);
        });

        const records = await fetchAllRecordsByYear(sensorIds, year);
        let sensorSetRecordIds = [];
        let recordIds = [];
        records.forEach((record) => {
          sensorSetRecordIds.push(record.sensorSetRecordId);
          recordIds.push(record.id);
        });
        sensorSetRecordIds = [...new Set(sensorSetRecordIds)];

        // initializing data objects for graph
        let monthlyTotalSavedEnergy;
        let monthlyTotalSavedEnergyByEachSet = [];
        let monthlySavedEnergy = [];
        let monthlyDates = [];
        let monthlyAmountOfShowersTaken = [];
        let monthlyTotalTimeInShower = [];

        // creating a full array of months
        MONTHS_OPTIONS.forEach((month) => {
          monthlyTotalSavedEnergyByEachSet.push(0);
          monthlyDates.push(month.name.substring(0, 3));
          monthlyAmountOfShowersTaken.push(0);
          monthlySavedEnergy.push(0);
          monthlyTotalTimeInShower.push(0);
        });

        let sensorSetRecords = await fetchSensorSetRecordsByYear(year, sensorSetRecordIds);
        if (typeof sensorSetRecords !== 'string') {
          // loop through each month and set the data available to the corresponding index (0 => january)
          monthlyDates.forEach((date, index) => {
            sensorSetRecords.forEach((sensorSetRecord) => {
              if (date === sensorSetRecord.month.substring(0, 3)) {
                monthlyTotalTimeInShower[index] = Number(sensorSetRecord.duration);
                monthlySavedEnergy[index] = Number(Math.abs(sensorSetRecord.sum).toFixed());
                monthlyAmountOfShowersTaken[index] = Number(sensorSetRecord.count);
                monthlyTotalSavedEnergyByEachSet[index] =
                  Number(Math.abs(sensorSetRecord.sum).toFixed()) / sensors.length;
              }
            });
          });
        } else {
          toast.warning(sensorSetRecords);
        }
        monthlyTotalSavedEnergy = monthlySavedEnergy.reduce((a, b) => {
          return a + b;
        }, 0);

        // setting the data
        setDates(monthlyDates);
        setTotalSavedEnergy(monthlyTotalSavedEnergy);
        setTotalSavedEnergyByEachSet(monthlyTotalSavedEnergyByEachSet);
        setSavedEnergy(monthlySavedEnergy);
        setAmountOfShowersTaken(monthlyAmountOfShowersTaken);
        setTotalTimeInShower(monthlyTotalTimeInShower);
      }
    } catch (error) {
      toast.error('Oops, er is iets misgegaan');
    }
  };

  return (
    <Box sx={{ p: 3, pb: 1 }} dir="ltr">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Formik
            initialValues={{ ...INITIAL_FORM_STATE }}
            onSubmit={onSubmitForm}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize={false}
          >
            {(props) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h3">Inzicht in energiebesparing</Typography>
                  </Grid>
                  {sensorsetOptions && sensorsetOptions.length > 0 && (
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        disabled={filter}
                        options={sensorsetOptions ?? []}
                        disableCloseOnSelect
                        sx={{
                          '.Mui-disabled': {
                            opacity: '0.5',
                          },
                        }}
                        onChange={(event, value) => (props.values.sensorset = value)}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecteer specifieke sensorsets"
                            name="sensorset"
                            placeholder="Selecteer specifieke sensorsets"
                          />
                        )}
                      />
                    </Grid>
                  )}

                  {loading ? (
                    <Box sx={{ p: 3, pb: 1 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Grid item xs={12}>
                      <Checkbox
                        checked={filter}
                        onChange={handleCheckedFilter}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      {`${filter ? 'Verberg filteropties' : 'Toon filteropties'}`}
                    </Grid>
                  )}

                  {/* FILTER AREA */}
                  {sensorsetOptions && sensorsetOptions.length > 0 && filter && (
                    <>
                      <Grid item xs={12}>
                        <Divider>
                          <Chip label="FILTER OPTIES" sx={{ fontWeight: 900 }} color="primary" />
                        </Divider>
                      </Grid>
                      {/* Groups */}
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          options={groupsOptions ?? []}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.groups = value)}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Groep(en)" name="groups" placeholder="Groep(en)" />
                          )}
                        />
                      </Grid>
                      {/* Projects */}
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          options={projectsOptions ?? []}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.projects = value)}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Project(en)" name="projects" placeholder="Project(en)" />
                          )}
                        />
                      </Grid>
                      {/* DWTW */}
                      <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                          multiple
                          options={dwtws ?? []}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.dwtw = value)}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="DWTW" name="dwtw" placeholder="DWTW" />
                          )}
                        />
                      </Grid>
                      {/* household */}
                      <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                          multiple
                          options={households ?? []}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.households = value)}
                          getOptionLabel={(option) => option.household}
                          isOptionEqualToValue={(option, value) => option.household === value.household}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.household}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Grootte huishouden"
                              name="households"
                              placeholder="Grootte huishouden"
                            />
                          )}
                        />
                      </Grid>
                      {/* tapwatergenerator */}
                      <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                          multiple
                          options={TAPWATERGENERATOR_OPTIONS}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.tapwatergenerators = value)}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Type tapwateropwekker"
                              name="tapwatergenerators"
                              placeholder="Type tapwateropwekker"
                            />
                          )}
                        />
                      </Grid>
                      {/* residence */}
                      <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                          multiple
                          options={RESIDENCE_OPTIONS}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.residences = value)}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Type woning" name="residences" placeholder="Type woning" />
                          )}
                        />
                      </Grid>
                      {/* city */}
                      <Grid item xs={12} md={12} lg={12}>
                        <Autocomplete
                          multiple
                          options={cities ?? []}
                          disableCloseOnSelect
                          onChange={(event, value) => (props.values.cities = value)}
                          getOptionLabel={(option) => option.city}
                          isOptionEqualToValue={(option, value) => option.city === value.city}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.city}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Woonplaats" name="cities" placeholder="Woonplaats" />
                          )}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} md={6} lg={12}>
                    <SelectUI name="timeline" label="Selecteer tijdlijn" options={TIMELINE_OPTIONS} />
                  </Grid>

                  {props.values.timeline === 'Dag' && (
                    <>
                      <Grid item xs={12} md={3} lg={3}>
                        <SelectUI
                          name="day"
                          label="Selecteer dag"
                          options={Array(getDaysInMonth(new Date(props.values.year, props.values.month - 1)) + 1)
                            .fill(null)
                            .map((_, index) => ({ id: index, name: index }))}
                        />
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <SelectUI name="month" label="Selecteer maand" options={MONTHS_OPTIONS} />
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <SelectUI name="year" label="Selecteer jaar" options={years ?? []} />
                      </Grid>
                    </>
                  )}

                  {props.values.timeline === 'Maand' && (
                    <>
                      <Grid item xs={12} md={6} lg={6}>
                        <SelectUI name="month" label="Selecteer maand" options={MONTHS_OPTIONS} />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <SelectUI name="year" label="Selecteer jaar" options={years ?? []} />
                      </Grid>
                    </>
                  )}

                  {props.values.timeline === 'Jaar' && (
                    <Grid item xs={12} md={6} lg={12}>
                      {(props.values.month = '')}
                      <SelectUI name="year" label="Selecteer jaar" options={years ?? []} />
                    </Grid>
                  )}

                  <Grid item xs={12} md={3} lg={4}>
                    <Button>{props.isSubmitting ? <CircularProgress /> : 'Bekijk resultaten'}</Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        {/* graph */}
        {dates && dates.length !== 0 && !loading ? (
          <Grid item xs={12}>
            {dates.length === 12 ? (
              <Graph
                title="Energiebesparing"
                chartLabels={dates}
                chartData={[
                  {
                    name: 'Totaal bespaarde energie (kJ)',
                    type: 'column',
                    fill: 'solid',
                    opacity: 1,
                    data: savedEnergy,
                  },
                  {
                    name: 'Aantal douchebeurten',
                    type: 'column',
                    fill: 'solid',
                    opacity: 1,
                    data: amountOfShowersTaken,
                  },
                  {
                    name: 'Bespaarde energie per sensorset (kJ)',
                    type: 'column',
                    fill: 'solid',
                    opacity: 0,
                    data: totalSavedEnergyByEachSet,
                  },

                  {
                    name: 'Totale doucheduur (minuten)',
                    type: 'column',
                    fill: 'solid',
                    opacity: 0,
                    data: totalTimeInShower,
                  },
                ]}
                yaxisOptions={[
                  {
                    axisTicks: {
                      show: true,
                    },
                    labels: {
                      formatter: function (value) {
                        return `${String(value)
                          .split('')
                          .reverse()
                          .join('')
                          .replace(/(\d{3}\B)/g, '$1.')
                          .split('')
                          .reverse()
                          .join('')} kJ`;
                      },
                      style: {
                        colors: '#00AEDF',
                      },
                    },
                    title: {
                      style: {
                        color: '#00AEDF',
                      },
                    },
                  },
                  {
                    opposite: true,
                    axisTicks: {
                      show: true,
                    },
                    labels: {
                      formatter: function (value) {
                        return `${String(value)
                          .split('')
                          .reverse()
                          .join('')
                          .replace(/(\d{3}\B)/g, '$1.')
                          .split('')
                          .reverse()
                          .join('')}`;
                      },
                      style: {
                        colors: '#2562c4',
                      },
                    },
                    title: {
                      style: {
                        color: '#2562c4',
                      },
                      text: 'Aantal douchebeurten',
                    },
                  },
                ]}
                subheader={undefined}
              />
            ) : (
              <Graph
                title="Energiebesparing"
                chartLabels={dates}
                chartData={[
                  {
                    name: 'Totaal bespaarde energie (kJ)',
                    type: 'column',
                    fill: 'solid',
                    opacity: 1,
                    data: savedEnergy,
                  },
                  {
                    name: 'Aantal douchebeurten',
                    type: 'column',
                    fill: 'solid',
                    opacity: 1,
                    data: amountOfShowersTaken,
                  },
                  {
                    name: 'Bespaarde energie per sensorset (kJ)',
                    type: 'column',
                    fill: 'solid',
                    opacity: 0,
                    data: totalSavedEnergyByEachSet,
                  },

                  {
                    name: 'Totale doucheduur (minuten)',
                    type: 'column',
                    fill: 'solid',
                    opacity: 0,
                    data: totalTimeInShower,
                  },
                ]}
                yaxisOptions={[
                  {
                    axisTicks: {
                      show: true,
                    },
                    labels: {
                      formatter: function (value) {
                        return `${String(value)
                          .split('')
                          .reverse()
                          .join('')
                          .replace(/(\d{3}\B)/g, '$1.')
                          .split('')
                          .reverse()
                          .join('')} kJ`;
                      },
                      style: {
                        colors: '#00AEDF',
                      },
                    },
                    title: {
                      style: {
                        color: '#00AEDF',
                      },
                    },
                  },
                  {
                    opposite: true,
                    axisTicks: {
                      show: true,
                    },
                    labels: {
                      formatter: function (value) {
                        return `${value.toFixed(0)}`;
                      },
                      style: {
                        colors: '#247BA0',
                      },
                    },
                    title: {
                      text: 'Douchebeurten',
                      style: {
                        color: '#247BA0',
                      },
                    },
                  },
                ]}
                subheader={undefined}
              />
            )}
          </Grid>
        ) : (
          <Box sx={{ p: 3, pb: 1 }}>
            <CircularProgress />
          </Box>
        )}
        {/* cards below graph */}
        {dates && dates.length !== 0 && !loading ? (
          <Grid item xs={12} md={4} lg={4}>
            <Card
              sx={{
                backgroundColor: '#00AEDF',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                boxShadow: 0,
                justifyContent: 'center',
                gap: '1rem',
              }}
            >
              <Box sx={{ p: 3 }} dir="ltr">
                <Typography variant="subtitle1" sx={{ fontWeight: 900, color: '#fff' }}>
                  Bespaarde energie (totaal)
                </Typography>
                <Typography variant="h3" sx={{ color: '#fff' }}>
                  {String(totalSavedEnergy)
                    .split('')
                    .reverse()
                    .join('')
                    .replace(/(\d{3}\B)/g, '$1.')
                    .split('')
                    .reverse()
                    .join('')}{' '}
                  kJ
                </Typography>
              </Box>
              <Box sx={{ p: 3 }}>
                <Typography sx={{ color: '#fff' }}>{getIcon('eva:flash-outline')}</Typography>
              </Box>
            </Card>
          </Grid>
        ) : (
          <Box sx={{ p: 3, pb: 1 }}>
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </Box>
  );
}
