import { Outlet } from 'react-router-dom';
import React from 'react';

export default function LogoOnlyLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}
