import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import { Navigate, Routes, Route } from 'react-router-dom';
import NotFound from 'src/pages/Page404';
import Shower from 'src/pages/Shower';
import Energy from 'src/pages/Energy';
import { AddSensor } from 'src/pages/AddSensor';
import Login from 'src/pages/Login';
import Home from 'src/pages/Home';
import { AddProject } from 'src/pages/AddProject';
import { AddGroup } from 'src/pages/AddGroup';
import { RequireAuth } from 'src/utils/RequireAuth';
import { AuthProvider } from 'src/utils/Auth';
import { AddDWTW } from 'src/pages/AddDWTW';
import Export from 'src/pages/Export';
import React from 'react';
import { DWTWs } from './pages/DWTWs';
import { Groups } from './pages/Groups';
import { Projects } from './pages/Projects';
import { Sensors } from './pages/Sensors';

export default function Router() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <DashboardLayout />
            </RequireAuth>
          }
        >
          <Route path="" element={<Home />} />
          <Route path="douchegedrag" element={<Shower />} />
          <Route path="energiebesparing" element={<Energy />} />
          {/* <Route path="upload" element={<Upload />} /> */}
          <Route path="sensors" element={<Sensors />} />
          <Route path="add-sensor" element={<AddSensor />} />
          <Route path="projects" element={<Projects />} />
          <Route path="add-project" element={<AddProject />} />
          <Route path="groups" element={<Groups />} />
          <Route path="add-group" element={<AddGroup />} />
          <Route path="dwtws" element={<DWTWs />} />
          <Route path="add-dwtw" element={<AddDWTW />} />
          <Route path="export" element={<Export />} />
        </Route>
        <Route path="/auth/login" element={<Login />} />
        <Route path="/" element={<LogoOnlyLayout />}>
          <Route path="/" element={<Navigate to="/auth/login" />} />
          <Route path="404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </AuthProvider>
  );
}
