import { Grid, Container, Typography } from '@mui/material';
import React from 'react';
import Page from 'src/components/Page';

export default function Home() {
  return (
    <Page title="Home">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <Typography variant="h2" color="primary">
              Welkom bij Sanura Insights.
            </Typography>
            <Typography variant="subtitle1">Dé oplossing voor inzicht in douchegedrag en energiebesparing.</Typography>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
