import React from 'react';

import { createContext, useState, useContext, useEffect } from 'react';
import { validate } from 'src/services/fetchApi';

const AuthContext = createContext<{ isAuthenticated: boolean | null; isLoggedIn: (value: boolean) => void }>(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    validate()
      .then((response) => {
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch((error) => {
        setIsAuthenticated(false);
      });
  }, []);

  const isLoggedIn = (value: boolean) => {
    setIsAuthenticated(value);
  };

  return <AuthContext.Provider value={{ isAuthenticated, isLoggedIn }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
