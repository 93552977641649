export const MONTHS_OPTIONS = [
  {
    id: 1,
    name: 'January',
  },
  {
    id: 2,
    name: 'February',
  },

  {
    id: 3,
    name: 'March',
  },

  {
    id: 4,
    name: 'April',
  },

  {
    id: 5,
    name: 'May',
  },

  {
    id: 6,
    name: 'June',
  },
  {
    id: 7,
    name: 'July',
  },
  {
    id: 8,
    name: 'August',
  },
  {
    id: 9,
    name: 'September',
  },
  {
    id: 10,
    name: 'October',
  },
  {
    id: 11,
    name: 'November',
  },
  {
    id: 12,
    name: 'December',
  },
];

export const TIMELINE_OPTIONS = [
  { id: 1, name: 'Dag' },
  { id: 2, name: 'Maand' },
  { id: 3, name: 'Jaar' },
];

export const YEAR_OPTIONS = [
  { id: 1, name: 2022 },
  { id: 2, name: 2023 },
  { id: 3, name: 2024 },
];

export const DWTW_OPTIONS = [
  { id: 1, name: 'Sanura Flatmate' },
  { id: 2, name: 'Sanura X-Tray' },
];

export const TAPWATERGENERATOR_OPTIONS = [
  { id: 1, name: 'Gasketel' },
  { id: 2, name: 'Elektrische boiler' },
  { id: 3, name: 'Doorstroomboiler' },
  { id: 4, name: 'Warmtenet' },
  { id: 5, name: 'Zonneboiler' },
];

export const RESIDENCE_OPTIONS = [
  { id: 1, name: 'Appartement' },
  { id: 2, name: 'Tussen/hoekwoning' },
  { id: 3, name: 'Twee onder een kap' },
  { id: 4, name: 'Vrijstaand' },
];

export const HOUSEHOLD_OPTIONS = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
];

export const UPLOAD_OPTIONS = [
  {
    id: 0,
    name: 'Upload data van een individuele sensor',
  },
  {
    id: 1,
    name: 'Upload data van een sensorset',
  },
];
