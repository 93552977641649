import { Grid, Container, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from 'src/components/Iconify';

import Page from 'src/components/Page';
import { SearchInput } from 'src/sections/@dashboard/app/SearchInput';
import { deleteDWTW, fetchAllDWTWs, fetchAllDWTWsFiltered } from 'src/services/fetchApi';

export function DWTWs() {
  const [searchValue, setSearchValue] = useState('');
  const [dwtws, setDWTWs] = useState<any[] | null>(null);

  const search = useCallback(async () => {
    setDWTWs(await fetchAllDWTWsFiltered(searchValue));
  }, [searchValue]);

  const _deleteDWTW = useCallback(
    async (id: number, name: string) => {
      if (window.confirm(`Weet je zeker dat je de DWTW "${name}" wilt verwijderen?`)) {
        const result = await deleteDWTW(id);

        if (result.status === 200) {
          toast.success(`DWTW "${name}" verwijderd`);
        } else {
          toast.error(`Er ging iets fout bij het verwijderen van de DWTW "${name}". Probeer het later nog eens`);
        }

        await search();
      }
    },
    [search]
  );

  useEffect(() => {
    async function fetch() {
      setDWTWs(await fetchAllDWTWs());
    }

    fetch();
  }, []);

  return (
    <Page title="DWTWs">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ mb: 3 }} style={{ display: 'flex', justifyContent: 'space-between' }}>
              DWTWs
              <Link to="/dashboard/add-dwtw">
                <Button variant="contained" color="primary" onClick={null}>
                  <Iconify icon="eva:plus-outline" sx={{ mr: 1 }} width={22} height={22} />
                  DWTW toevoegen
                </Button>
              </Link>
            </Typography>

            <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} search={search} />

            {dwtws ? (
              dwtws.length === 0 ? (
                <Typography style={{ marginTop: '1rem' }} variant="body1">
                  Geen DWTWs gevonden
                </Typography>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Naam</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {dwtws.map((dwtw) => (
                      <TableRow key={dwtw.id}>
                        <TableCell>{dwtw.id}</TableCell>
                        <TableCell>{dwtw.name}</TableCell>
                        <TableCell width={'4rem'}>
                          <Button variant="contained" color="error" onClick={() => _deleteDWTW(dwtw.id, dwtw.name)}>
                            <Iconify icon="eva:trash-2-outline" width={22} height={22} sx={undefined} />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )
            ) : (
              <Iconify icon="eos-icons:loading" sx={undefined} width={40} height={40} />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
