import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Typography, CircularProgress } from '@mui/material';
import { Formik, Form } from 'formik';
import { sensorFormSchema } from 'src/utils/formSchemas';
import { CheckBoxField, Select, TextField, DateField, Button } from 'src/components/FormsUI';
import { toast } from 'react-toastify';
import { TAPWATERGENERATOR_OPTIONS, RESIDENCE_OPTIONS } from 'src/utils/selectItems';
import 'react-toastify/dist/ReactToastify.css';
import { createSensor, fetchAllGroups, fetchAllProjects, fetchAllDWTWs } from 'src/services/fetchApi';
import { useNavigate } from 'react-router-dom';

const INITIAL_FORM_STATE = {
  name: '',
  isSet: false,
  idSensor1: '',
  idSensor2: '',
  dwtw: '',
  group: '',
  project: '',
  postalCode: '',
  city: '',
  household: '',
  datePlacement: '',
  dateRemoval: '',
  tapwatergenerator: '',
  residence: '',
  comments: '',
};

export default function SensorForm() {
  const navigate = useNavigate();

  const [projects, setProjects] = useState('');
  const [groups, setGroups] = useState('');
  const [dwtws, setDWTWS] = useState('');

  const onSubmitForm = useCallback(
    async (values) => {
      try {
        // converting city names to uppercase to avoid duplicates
        values.city = values.city.toUpperCase();
        let response = await createSensor(values);
        toast.info(response);

        navigate('/dashboard/sensors');
      } catch (error) {
        toast.error('Oops, er is iets misgegaan');
        console.log(error);
      }
    },
    [navigate]
  );

  useEffect(() => {
    const fetch = async () => {
      setGroups(await fetchAllGroups());
      setProjects(await fetchAllProjects());
      setDWTWS(await fetchAllDWTWs());
    };
    fetch();
  }, []);

  return (
    <Box>
      <Formik
        initialValues={{ ...INITIAL_FORM_STATE }}
        validationSchema={sensorFormSchema}
        onSubmit={onSubmitForm}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {(props) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: 500 }}>Sensorgegevens</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField name="name" label="Naam" />
              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }}>
                <CheckBoxField name="isSet" legend="Zal de sensor deel uitmaken van een set?" label={'Ja'} />
              </Grid>

              {props.values.isSet ? (
                <>
                  <Grid item xs={12}>
                    <TextField name="idSensor1" label="ID Sensor 1" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField name="idSensor2" label="ID Sensor 2" />
                  </Grid>
                </>
              ) : (
                <>
                  {(props.values.idSensor2 = '')}
                  <Grid item xs={12}>
                    <TextField name="idSensor1" label="ID Sensor 1" />
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={6} lg={6}>
                <Select name="group" label="Groep" options={groups} />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Select name="project" label="Project" options={projects} />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontWeight: 500 }}>Locatiegegevens</Typography>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextField name="postalCode" label="Postcode" />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField name="city" label="Woonplaats" />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextField name="household" label="Grootte huishouden" />
              </Grid>

              <Grid item xs={12} md={6} lg={8}>
                <Select name="dwtw" label="DWTW" options={dwtws} />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Select name="tapwatergenerator" label="Tapwateropwekker" options={TAPWATERGENERATOR_OPTIONS} />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Select name="residence" label="Woning" options={RESIDENCE_OPTIONS} />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <DateField name="datePlacement" label="Datum geplaatst" />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <DateField name="dateRemoval" label="Datum verwijderd" />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontWeight: 500 }}>Extra</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField name="comments" label="opmerkingen" multiline={true} rows={5} />
              </Grid>

              <Grid item xs={12} md={6} lg={2}>
                <Button>{props.isSubmitting ? <CircularProgress /> : 'Maak sensor aan'}</Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
