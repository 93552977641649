import { Grid, Container, Typography } from '@mui/material';
import React from 'react';
import Page from 'src/components/Page';
import { ExportRecords } from 'src/sections/@dashboard/app';

export default function Export() {
  return (
    <Page title="Export">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="h4">Export</Typography>
          </Grid>
          <ExportRecords />
        </Grid>
      </Container>
    </Page>
  );
}
